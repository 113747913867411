const landing_vi = {
  leftPanel: [
    'MANG ĐẾN',
    'DỊCH VỤ OUTSOURCE',
    'CAD/CAM/CAE',
    'CHẤT LƯỢNG CAO',
    'Đồng thời, chúng tôi thực hiện các hoạt động nghiên cứu và phát triển cũng như giáo dục, đào tạo để đạt được những bước tiến quan trọng về khoa học và kỹ thuật.',
    '/my-content/resources/ex/landing-vn.svg',
  ],
  slideShowImageList: [
    '/my-content/resources/landingpage01.png',
    '/my-content/resources/landingpage02.png',
    '/my-content/resources/landingpage03.png',
    '/my-content/resources/engine.png',
  ],

  hookingQuestions: [
    {
      description: 'Hợp tác với chúng tôi?',
      link: '',
      position: 'telephone',
      location: 'tel:12312312312',
    },
    {
      description: 'Xem xét khả năng outsourcing của chúng tôi?',
      link: '',
      position: 'outsourcing',
      location: '',
    },
    {
      description: 'Tìm kiếm cơ hội tuyển dụng?',
      link: '/opportunities',
      position: 'redirect',
      location: '/vi/opportunities',
    },
    {
      description: 'Nhận thông tin mới nhất từ chúng tôi?',
      link: '',
      position: 'footer',
      location: '',
    },
  ],
  callToAction: {
    buttonName: 'LIÊN HỆ NGAY',
    textboxPlaceHolder: 'Bạn đang tìm kiếm gì?',
  },
};

export default landing_vi;
