import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import * as actions from '../actions';
import { connect } from 'react-redux';

class MobileNavBar extends Component {
  displayName = MobileNavBar.name;

  // constructor(props) {
  //   super(props);
  // }

  onLanguageChange(item) {
    var nextLocation =
      '/' + item.split('-')[0] + window.location.pathname.slice(3);
    if (window.location.pathname !== nextLocation) {
      window.location.pathname = nextLocation;
    }
  }

  renderLang() {
    return (
      <div className="langIconWrap">
        <div
          className="langIcon"
          onClick={() => this.onLanguageChange('vi-VN')}
        >
          <img
            alt="vnflag"
            src="/my-content/resources/vietnam.svg"
            width="40px"
            height="40px"
          />
        </div>
        <div
          className="langIcon"
          onClick={() => this.onLanguageChange('ja-JP')}
        >
          <img
            alt="jaflag"
            src="/my-content/resources/japan.svg"
            width="40px"
            height="40px"
          />
        </div>
        <div
          className="langIcon"
          onClick={() => this.onLanguageChange('en-US')}
        >
          <img
            src="/my-content/resources/united-kingdom.svg"
            width="40px"
            height="40px"
            alt="enflag"
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="MobileNavBar" className="MobileNavBar">
        <div className="MobileNavBarHeader">
          <div className="MobileNavBarHeaderLeft">{this.renderLang()}</div>
        </div>

        <div className="listItem">
          {this.props.menus.map((item, index) => {
            if (item.dropdown === true && !item.isExtended) {
              return (
                <div key={index}>
                  <hr className="ml-15"></hr>
                  <div className="itemOfListItem">
                    <NavLink
                      onClick={() => {
                        this.props.onNavItemClick();
                      }}
                      to={item.path}
                    >
                      <div style={{ width: '100%' }}>
                        <h6>{item.title}</h6>
                      </div>
                    </NavLink>
                  </div>
                </div>
              );
            } else if (!item.isExtended) {
              return (
                <div key={index}>
                  <hr className="ml-15"></hr>
                  <div className="itemOfListItem">
                    <NavLink
                      onClick={() => {
                        this.props.onNavItemClick();
                      }}
                      to={item.path}
                    >
                      <div style={{ width: '100%' }}>
                        <h6>{item.title}</h6>
                      </div>
                    </NavLink>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, actions)(MobileNavBar);
