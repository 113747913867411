import React from 'react';
import {
  InputGroup,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Form,
} from 'react-bootstrap';

function QuickInfoToDownload(props) {
  const handleSubmitEmailToDownload = (e) => {
    e.preventDefault();
    props.requestSendDocuments(props.content.uuid, e.target.email.value, props);
    props.showModalContent('loading');
  };

  return (
    <div>
      <Form
        onSubmit={(e) => {
          handleSubmitEmailToDownload(e);
        }}
      >
        <FormGroup>
          <ControlLabel>Enter your email address</ControlLabel>
          <InputGroup>
            <FormControl id="email" type="email" />
            <InputGroup.Button>
              <Button type="submit">Send</Button>
            </InputGroup.Button>
          </InputGroup>
        </FormGroup>
      </Form>
    </div>
  );
}

export default QuickInfoToDownload;
