import React, { Component } from 'react';
import {
  Row,
  Col,
  Grid,
  Button,
  Tabs,
  Tab,
  PageHeader,
  Table,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import axios from 'axios';
import skeleton from './skeleton';
import ProductItemPortrait from './ProductItemPortrait';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import Slider from 'react-slick';

var settings = {
  dots: true,
  slidesToShow: 4,
  adaptiveHeight: true,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: 'linear',
  arrows: false,
  swipeToSlide: true,
};

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bigImage: '',
    };
    this.productId = this.props.match.params.id;
  }

  handleSendDocumentsToEmail(e, fileuuid) {
    e.preventDefault();

    this.props.showModalContent('quick-info', { uuid: fileuuid });
  }

  handleDownloadDocs(e, item) {
    e.preventDefault();
    if (!this.props.auth) {
      this.props.showModalContent('sign-in-to-continue');
    } else {
      axios.get(item.filepath, { responseType: 'blob' }).then((response) => {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], {
          type: type,
          encoding: 'UTF-8',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = response.headers;
        link.download = response.config.url.split('/')[
          response.config.url.split('/').length - 1
        ];
        link.click();
      });
    }
  }

  componentDidMount() {
    this.props.getProductById(this.productId, this.props.lang);
    this.props.fetchUser();
  }

  renderListProductImages(imgArr) {
    const handleOnDragStart = (e) => e.preventDefault();
    if (!Array.isArray(imgArr)) return null;
    var a = this;
    var listItems = imgArr.map((item, index) => {
      return (
        <div key={index} className="product-description-item">
          <img
            alt="list of products"
            src={item.image}
            onClick={() => {
              this.setState({ bigImage: item.image });
            }}
          />
        </div>
      );
    });

    if (imgArr.length < 4) {
      return imgArr.map((item, index) => {
        return (
          <Col key={index} xs={4}>
            <div className="product-description-item">
              <img
                alt="list of products"
                src={item.image}
                onClick={() => {
                  this.setState({ bigImage: item.image });
                }}
              />
            </div>
          </Col>
        );
      });
    }

    return <Slider {...settings}>{listItems}</Slider>;
  }

  renderTab(tabName, data) {
    if (tabName === 'catalogues') {
      return (
        <Table striped bordered condensed>
          <thead>
            <tr>
              <th>ID</th>
              <th>Filename</th>
              <th>Download</th>
              <th>Send To Email</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.filename}</td>
                  <td>
                    <a
                      onClick={(e) => {
                        this.handleDownloadDocs(e, item);
                      }}
                      style={{ cursor: 'default' }}
                    >
                      Click here to download
                    </a>
                  </td>
                  <td>
                    <a
                      onClick={(e) => {
                        this.handleSendDocumentsToEmail(e, item.uuid);
                      }}
                      style={{ cursor: 'default' }}
                    >
                      Click here to send
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    }
    return (
      <div
        className="html-content__tiny-mce"
        dangerouslySetInnerHTML={{ __html: data }}
      ></div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.info !== prevProps.info) {
      if (this.props.info !== null) {
        var mulCatId = this.props.info.mulCatId;
        if (mulCatId !== null) {
          this.props.getProductsByCatId(mulCatId, this.props.lang);
        }
      }
      if (this.props.images.length === 0) return;
      this.setState({ bigImage: this.props.images[0].image });
    }
  }

  renderSamCatProducts(arr) {
    if (!Array.isArray(arr)) return null;
    return (
      <div className="section-padding-0-100">
        {arr.map((i, index) => (
          <Col key={index} xs={6} sm={6} md={4} lg={3}>
            <ProductItemPortrait
              lang={this.props.lang}
              key={index}
              needShadow={true}
              title={i.name}
              description={i.description}
              imgSrc={i.bannerImage}
              views={i.views}
              path={'/' + this.props.lang.slice(0, 2) + i.path}
            />
          </Col>
        ))}
      </div>
    );
  }

  renderProduct(prodObj, lang) {
    if (typeof prodObj !== 'object' || prodObj === null) return null;
    if (prodObj.info === null) return null;

    return (
      <div className="productWrapper mt-30">
        <Row className="">
          <Col xsHidden sm={2} mdHidden lgHidden></Col>
          <Col xs={12} sm={8} md={5} lg={5} className="leftProductPanel">
            <div className="productImage">
              <div className="productImageBig">
                {!this.state.bigImage ? (
                  <Skeleton width="100%" height={500} />
                ) : (
                  <img src={this.state.bigImage} alt="big size product" />
                )}
              </div>
              <div className="productImageList mt-15">
                {this.renderListProductImages(prodObj.images)}
              </div>
            </div>
          </Col>
          <Col xsHidden sm={2} mdHidden lgHidden></Col>
          <Col xs={12} sm={12} md={7} lg={7} className="rightProductPanel">
            <div className="rightPanelNavBar">
              <div className="productOverview">
                <h2>{prodObj.info.name || <Skeleton count={1} />}</h2>
                <hr />
                <p>{prodObj.info.description || <Skeleton count={5} />}</p>
              </div>

              <div className="wrapper--productTransact">
                <div className="productTransact">
                  <Button bsSize="large" className="btn--order-normal">
                    <i className="fa fa-shopping-cart"></i> &nbsp;
                    <p>{skeleton.button.order[lang]}</p>
                  </Button>

                  <Button bsSize="large" className="btn--order-zalo">
                    <img
                      alt="zalo icon"
                      src="/my-content/images/Logo_Zalo.png"
                    />
                    <p>Zalo Chat</p>
                  </Button>

                  <Button bsSize="large" className="btn--order-normal">
                    <i className="fa fa-phone"></i>&nbsp;
                    <p>+84 931477868</p>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="mt-40">
              <Tabs
                activeKey={this.state.key}
                onSelect={this.handleSelect}
                className="tabs-product-info"
                id="controlled-tab-example"
              >
                <Tab eventKey={1} title={skeleton.tabs.introduction[lang]}>
                  {this.renderTab('introduction', prodObj.info.introduction)}
                </Tab>
                <Tab eventKey={2} title={skeleton.tabs.technical[lang]}>
                  {this.renderTab(
                    'technical',
                    prodObj.info.technical_information
                  )}
                </Tab>
                <Tab eventKey={3} title={skeleton.tabs.other[lang]}>
                  {this.renderTab('other', prodObj.info.other_information)}
                </Tab>
                <Tab eventKey={4} title={skeleton.tabs.documents[lang]}>
                  {this.renderTab('catalogues', prodObj.catalogues)}
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { info, images, lang, catalogues } = this.props;
    var { children } =
      this.props.products !== null ? this.props.products : { children: null };
    return (
      <Grid className="section-padding-80">
        <Helmet>
          <title>{info.name || ''}</title>
          <meta property="og:title" content={info.name || ''} />
          <meta property="og:description" content={info.description || ''} />
        </Helmet>

        {info === {}
          ? null
          : this.renderProduct({ info, images, catalogues }, lang)}

        <Row>
          <h3 className="ml-15">{skeleton.sameCat[lang]}</h3>
        </Row>

        <Row>
          {children === null
            ? null
            : children.length > 0
            ? this.renderSamCatProducts(children)
            : null}
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps({
  aproduct: { info, images, catalogues },
  products,
  auth,
}) {
  return {
    info,
    images,
    catalogues,
    products,
    auth,
  };
}

function loadData(store, lang, params) {
  return Promise.all([
    store.dispatch(actions.getProductById(params.id, lang)),
    store.dispatch(actions.fetchUser()),
  ]);
}

export default {
  component: connect(mapStateToProps, actions)(Products),
  loadData,
};
