import React from 'react';

export default function LoadMoreButton(props) {
  var isLoading = props.isLoading || false;
  return (
    <div
      onClick={() => {
        !isLoading && props.onLoadMore();
      }}
      className={
        isLoading
          ? 'btn--load-more-content blueback'
          : 'btn--load-more-content whiteback'
      }
    >
      {isLoading ? <div className="lds-dual-ring"></div> : <h5>LOAD MORE</h5>}
    </div>
  );
}
