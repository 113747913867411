import React from 'react';
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Form,
  InputGroup,
  Row,
  Grid,
  Col,
  HelpBlock,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../actions';
import axios from 'axios';
import { Done, DoneError, Unknown, Loading } from './insidemodal/Done';
import QuickAuthorization from './insidemodal/QuickAuthorization';
import QuickInfoToDownload from './insidemodal/QuickInfoToDownload';

var axiosInstance = axios.create({
  baseURL: '/',
});

async function requestSendDocuments(documentUuid, userMail, props) {
  try {
    var result = await axiosInstance.post('/api/utils/send-docs', {
      documentUuid,
      userMail,
    });
    if ((result.data.status = 'success')) {
      return props.showModalContent(
        'request-ok',
        'Please check your email! The file has been sent to your email'
      );
    }
    return props.showModalContent(
      'request-failed',
      'Error happened, please try again later!'
    );
  } catch (error) {
    return props.showModalContent(
      'request-failed',
      'Error happened, please try again later!'
    );
  }
}

function renderContent(topic, content, props) {
  switch (topic) {
    case 'ambiguous':
      return <Unknown content={content} />;
    case 'request-ok':
      return <Done content={content} />;
    case 'request-failed':
      return <DoneError content={content} />;
    case 'loading':
      return <Loading />;
    case 'quick-info':
      return (
        <QuickInfoToDownload
          content={content}
          {...props}
          requestSendDocuments={(uuid, email, props2) => {
            requestSendDocuments(uuid, email, props2);
          }}
        />
      );
    case 'sign-in-to-continue':
      return <QuickAuthorization {...props} />;
    default:
      return <Unknown />;
  }
}

class AkysModal extends React.Component {
  render() {
    var show = this.props.show || false;
    var content = this.props.content || 'ambiguous';
    var topic = this.props.topic || 'ambiguous';
    var lang = this.props.lang;
    return (
      <Modal
        show={show}
        onClose={() => this.props.onClose()}
        onHide={() => this.props.onClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title>ITSC Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderContent(topic, content, this.props)}</Modal.Body>
        <Modal.Footer> </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(null, actions)(AkysModal);
