import NotFoundPage from './pages/NotFoundPage';
import App from './App';
import Posts from './pages/posts/Posts';
import Home from './components/Home';
import News from './pages/news/News';
import Opportunities from './pages/opportunities/Opportunities';
import Products from './pages/products/Products';
import ProductsL1 from './pages/products/ProductsL1';
import ProductsL2 from './pages/products/ProductsL2';
import ProductsL3 from './pages/products/ProductsL3';
import ServiceSingle from './pages/services/ServiceSingle';
import SignIn from './components/SignIn';
import SignUp from './pages/authorization/SignUp';

const baseRoutes = [
  {
    path: '/',
    ...Home,
    exact: true,
  },
  {
    path: '/news',
    ...News,
  },
  {
    path: '/posts/:path',
    ...Posts,
  },
  {
    path: '/opportunities',
    ...Opportunities,
  },
  {
    path: '/products/models/:id',
    ...Products,
  },
  {
    path: '/products/:l2Id/:l3Id',
    ...ProductsL3,
  },
  {
    path: '/products/:l2Id',
    ...ProductsL2,
  },
  {
    path: '/all-products',
    ...ProductsL1,
  },
  {
    path: '/services/:serviceNameId',
    ...ServiceSingle,
  },
  {
    path: '/sign-up',
    ...SignUp,
  },
];

const testRoutes = [
  {
    ...App,
    path: '/en',
    lang: 'en-US',
    routes: [
      ...baseRoutes.map((route) => {
        return {
          ...route,
          path: '/en' + route.path,
          lang: 'en-US',
        };
      }),
      {
        path: '/en',
        lang: 'en-US',
        ...NotFoundPage,
      },
    ],
  },
  {
    ...App,
    path: '/ja',
    lang: 'ja-JP',
    routes: [
      ...baseRoutes.map((route) => {
        return {
          ...route,
          path: '/ja' + route.path,
          lang: 'ja-JP',
        };
      }),
      {
        path: '/ja',
        lang: 'ja-JP',
        ...NotFoundPage,
      },
    ],
  },
  {
    ...App,
    path: '/vi',
    lang: 'vi-VN',
    routes: [
      ...baseRoutes.map((route) => {
        return {
          ...route,
          path: '/vi' + route.path,
          lang: 'vi-VN',
        };
      }),
      {
        path: '/vi',
        lang: 'vi-VN',
        ...NotFoundPage,
      },
    ],
  },
  {
    ...App,
    path: '/',
    exact: true,
    lang: 'vi-VN',
    routes: [
      {
        ...Home,
        lang: 'vi-VN',
      },
    ],
  },
  {
    component: SignIn,
    path: '/login',
    exact: true,
    lang: 'vi-VN',
  },
  {
    lang: 'vi-VN',
    ...NotFoundPage,
  },
];

export default testRoutes;
