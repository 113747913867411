import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import axios from 'axios';

const langItems = [
  {
    iconClass: 'sl-flag flag-usa',
    title: 'EN',
    value: 'en-US',
    pathway: 'en',
  },
  {
    iconClass: 'sl-flag flag-jp',
    title: '日本語',
    value: 'ja-JP',
    pathway: 'ja',
  },
  {
    iconClass: 'sl-flag flag-vn',
    title: 'VN',
    value: 'vi-VN',
    pathway: 'vi',
  },
];

class AuthCorner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: 'EN',
    };
  }

  componentDidMount() {
    this.props.fetchUser();
  }

  handleLogout(e) {
    e.preventDefault();
    axios
      .create({
        baseURL: '/',
      })
      .get('/api/auth/logout')
      .then((response) => {
        if (response.data.status) {
          window.location.reload();
        }
      });
  }

  render() {
    var { lang, auth } = this.props;
    return (
      <div id="authCorner" className="nav-wrapper">
        {!auth ? (
          <span>
            <a style={{ cursor: 'pointer' }} href="/login">
              Login
            </a>
          </span>
        ) : (
          <span>
            <a
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                this.handleLogout(e);
              }}
            >
              Logout
            </a>
          </span>
        )}
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      console.log(this.props.lang);
    }
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, actions)(AuthCorner);
