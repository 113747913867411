import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import AliceCarousel from 'react-alice-carousel';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Skeleton from 'react-loading-skeleton';
import Slider from 'react-slick';

var settings = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        autoPlay: true,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoPlay: true,
      },
    },
  ],
};

class RAndD extends Component {
  displayName = RAndD.name;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getRD(this.props.lang);
    // setTimeout(() => {
    //   console.log(this.props.detail);
    // }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lang !== prevProps.lang) {
      this.props.getRD(this.props.lang);
    }
  }

  render() {
    const handleOnDragStart = (e) => e.preventDefault();
    const { overview, detail } = this.props;
    return (
      <Grid id="rdPage" className="rAndDPage">
        <Row className="home--overview">
          <Col xs={12}>
            <h3>&nbsp;{overview.display_name || <Skeleton />}</h3>
            <div className="home--description">
              <p>{overview.description || <Skeleton count={5} />}</p>
            </div>
          </Col>
        </Row>

        <Row className="_1BootBlock">
          {detail.length > 0 && (
            <Col xs={12}>
              {/* <div className="dragIcon prev">
                <img
                  src="/my-content/resources/left-chevron.svg"
                  alt="previous icon"
                />
              </div> */}

              <div className="slidingDivItemList">
                <Slider {...settings}>
                  {detail.map((item, index) => {
                    return (
                      <div key={index} className="slidingDivItem">
                        <img alt="carousel sliding items" src={item.image} />
                        <div className="bottom">
                          <h4>{item.tag}</h4>
                          <p className="p--small">{item.detail}</p>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {/* 
              <div className="dragIcon next">
                <img
                  src="/my-content/resources/right-chevron.svg"
                  alt="next Icon"
                />
              </div> */}
            </Col>
          )}
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps({ rd: { overview, detail } }) {
  return {
    overview,
    detail,
  };
}

export default connect(mapStateToProps, actions)(RAndD);
