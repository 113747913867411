import React, { Component } from 'react';
import { Row, Col, Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { changeAlias } from '../../utils/ConvertVie';
import SearchPostWidget from './SearchPostWidget';
import PostCategoryWidget from './PostCategoryWidget';
import RecentPostWidget from './RecentPostWidget';
import LoadMoreButton from './LoadMoreButton';
import { Helmet } from 'react-helmet';

var skeleton = {
  nocontent: {
    'vi-VN': 'KHÔNG CÓ BÀI VIẾT',
    'en-US': 'NO CONTENT FOUND',
    'ja-JP': '内容なし',
  },
  pageTitle: {
    'vi-VN': 'ITSC - Tin Tức, Bài Viết',
    'en-US': 'ITSC - News',
    'ja-JP': 'ITSC - ニュース',
  },
};

class News extends Component {
  displayName = News.name;

  constructor(props) {
    super(props);
    this.state = {
      nextPage: Math.ceil(this.props.posts.length / 3) + 1,
      isLoadingMore: false,
    };
  }

  receiveData() {
    return this.props.posts.map((item, index) => {
      var titleChunks = changeAlias(item.title).split(' ').join('-');
      var newHref =
        '/' +
        this.props.lang.slice(0, 2) +
        '/posts/' +
        titleChunks +
        '_akys_' +
        item.post_id;

      return (
        <div
          key={index}
          onClick={() => {
            this.props.history.push(
              '/' +
                this.props.lang.slice(0, 2) +
                '/posts/' +
                titleChunks +
                '_akys_' +
                item.post_id
            );
          }}
          className="single-post-item"
        >
          <img className="post-thumbnail" src={item.bannerImage} alt="" />
          <a href={newHref} className="post-title">
            <h3>{item.title}</h3>
          </a>
          <div className="post-meta">
            <span className="post-date">December 9, 2018 | </span>
            <a href="/" className="post-author">
              {item.author}
            </a>
            <span> | </span>
            <a href="/" className="post-tutorial">
              Tutorials
            </a>
          </div>
          <p className="post-short-descript">{item.description}</p>
          <a href={newHref} className="btn continue-btn">
            Continue Reading <i className="arrow_right" />
          </a>
        </div>
      );
    });
  }

  componentDidMount() {
    if (this.props.posts.length === 0) {
      this.props.getPost(1, this.props.lang);
    }
    this.props.getAllCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lang !== prevProps.lang) {
      this.props.getPost(this.state.nextPage - 1, this.props.lang);
    }
  }

  onLoadMore() {
    this.setState({ isLoadingMore: true });
    this.props.getPost(this.state.nextPage, this.props.lang);
    this.setState({ nextPage: this.state.nextPage + 1 });
    setTimeout(() => {
      this.setState({ isLoadingMore: false });
    }, 1000);
  }

  render() {
    var lang = this.props.lang || 'en-US';

    return (
      <Grid className="mt-70">
        <Helmet>
          <title>{skeleton.pageTitle[this.props.lang]}</title>
          <meta
            name="description"
            content={skeleton.pageTitle[this.props.lang]}
          />
        </Helmet>
        <Row className="akys-page-wrapper">
          <Col xs={12} md={7} lg={8}>
            {this.props.posts === null || this.props.posts.length === 0 ? (
              <div className="section-padding-80">
                <h5 className="fc__gray">{skeleton.nocontent[lang]}</h5>
              </div>
            ) : (
              this.receiveData()
            )}
            <div className="mt-30 mb-30 news-load-more">
              <LoadMoreButton
                isLoading={this.state.isLoadingMore}
                onLoadMore={() => {
                  this.onLoadMore();
                }}
              />
            </div>
          </Col>

          <Col xs={12} md={5} lg={4}>
            <SearchPostWidget lang={lang} />
            <PostCategoryWidget
              lang={lang}
              categories={this.props.categories}
            />
            <RecentPostWidget lang={lang} posts={this.props.posts} />
          </Col>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps({ posts, categories, lang }) {
  return {
    posts,
    categories,
  };
}

function loadData(store, lang) {
  return Promise.all([
    store.dispatch(actions.getAllCategories()),
    store.dispatch(actions.getPost(1, lang)),
  ]);
}

export default {
  component: connect(mapStateToProps, actions)(News),
  loadData,
};
