import React, { Component } from 'react';
import { Col, Row, Table, Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import AliceCarousel from 'react-alice-carousel';
//import "react-alice-carousel/lib/alice-carousel.css";
import RunningStatistics from './RunningStatistics';
import Skeleton from 'react-loading-skeleton';
import Slider from 'react-slick';

var settings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 700,
      settings: {
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
};

var skeleton = {
  _ourcustomer: {
    'vi-VN': 'KHÁCH HÀNG CỦA CHÚNG TÔI',
    'en-US': 'OUR CUSTOMERS',
    'ja-JP': '私たちの顧客',
  },
  readMore: {
    'vi-VN': 'Xem thêm',
    'en-US': 'Read more',
    'ja-JP': '続きを読む',
  },
};

function DropList(props) {
  var { data, componentId } = props;
  return (
    <div>
      {!data ? null : (
        <div className="mb-15 common-shadow">
          <div className="wrapper--table-dropdown-top">
            <h4>
              {data.tag} &nbsp;
              <i
                onClick={() => {
                  props.onDropdown('wrapper--table__' + componentId, 600);
                }}
                id={'btn--wrapper--table__' + componentId}
                className="fa fa-chevron-circle-down"
              ></i>
            </h4>
          </div>
          <div
            id={'wrapper--table__' + componentId}
            className="wrapper--table-dropdown-content"
          >
            <Table bordered striped hover>
              <tbody>
                {data.detail.split('_akyssp_').map((item, index) => (
                  <tr key={index}>
                    <td>
                      <p>{String(index + 1) + '. ' + item}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}

class Outsourcing extends Component {
  displayName = Outsourcing.name;
  constructor(props) {
    super(props);
    this.state = {};
  }

  getListOfCustomerItems(customers) {
    return customers.map((item, index) => {
      return (
        <div key={index} className="customerItem">
          <img
            alt="customer list"
            src={item.image}
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            width="200px"
            height="auto"
          />
        </div>
      );
    });
  }

  onDropdown(id) {
    var indicator = document.getElementById(id);
    if (indicator.classList.contains('wrapper--table-dropdown-content__open')) {
      indicator.classList.remove('wrapper--table-dropdown-content__open');
      document
        .getElementById('btn--' + id)
        .classList.remove('btn--table-reverse');
    } else {
      indicator.classList.add('wrapper--table-dropdown-content__open');
      document.getElementById('btn--' + id).classList.add('btn--table-reverse');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang !== prevProps.lang) {
      this.props.getOutsourcingServices(this.props.lang);
    }
  }

  componentDidMount() {
    this.props.getListOfCustomer();
    this.props.getOutsourcingServices(this.props.lang);
    this.props.getAllServices(this.props.lang);
  }

  render() {
    const { customers, overview, detail, serviceList, history } = this.props;
    return (
      <Grid id="outsourcingPage" className="outsourcingPage">
        <Row className="home--overview">
          <Col xs={12}>
            <h3>{overview.display_name || <Skeleton />}</h3>
            <div className="home--description">
              <p>{overview.description || <Skeleton count={5} />}</p>
            </div>
          </Col>
        </Row>

        <Row className="mb-15">
          {serviceList.slice(3, 7).map((item, index) => {
            return (
              <Col
                key={index}
                xs={6}
                sm={3}
                className="wrapper--outsource-item"
              >
                <div className="item--outsourcing-service">
                  <div className="mt-15 wrapper--title">
                    <h4>{item.display_name || <Skeleton count={5} />}</h4>
                  </div>
                  <img alt="yeah redundant something" src={item.thumbnail} />
                  <div className="button--read-more mt-15 mb-15">
                    <a href={'/' + this.props.lang.slice(0, 2) + item.path}>
                      <span>{skeleton.readMore[this.props.lang]} ></span>
                    </a>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>

        <Row className="_11BootBlock">
          <Col xs={12}>
            {!detail[0] ? null : (
              <div className="wrapper--image-background">
                <div className="content--image-background">
                  <h4>{detail[0].tag}</h4>
                  <div>
                    <p className="p--small">{detail[0].detail}</p>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Row className="_11BootBlock mt-15">
          <Col xs={12} sm={12} md={12} lg={12}>
            <DropList
              data={detail[4]}
              componentId="strength"
              onDropdown={this.onDropdown.bind(this)}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <DropList
              data={detail[5]}
              componentId="worktypes"
              onDropdown={this.onDropdown.bind(this)}
            />
          </Col>
        </Row>

        <Row className="_111BootBlock">
          {detail.slice(1, 4).map((item, index) => {
            return (
              <Col key={index} xs={12} sm={4} md={4} lg={4}>
                <div className="leftBoot111Block">
                  <div className="circleBlock">
                    <img src={item.image} alt="okiknow" />
                    <h4>{item.tag}</h4>
                  </div>
                  <p className="alignTextCenter p--small">{item.detail}</p>
                </div>
              </Col>
            );
          })}
        </Row>

        <Row className="_111BootBlock mb-30">
          <RunningStatistics
            lang={this.props.lang}
            enable={this.props.enableRunningStats}
          />
        </Row>

        <Row className="_1BootBlock">
          <Col xs={12}>
            <div className="customerSlider mt-30">
              <div className="customer-title">
                <h4>{skeleton._ourcustomer[this.props.lang]}</h4>
              </div>
              <div className="customerSliderList">
                {
                  customers == null ? (
                    <Skeleton count={3} />
                  ) : (
                    <Slider {...settings}>
                      {this.getListOfCustomerItems(customers)}
                    </Slider>
                  )
                  // <AliceCarousel
                  //   ref={(el) => (this.Carousel = el)}
                  //   mouseTrackingEnabled
                  //   autoPlayInterval={1000}
                  //   autoPlayDirection="rtl"
                  //   autoPlay={true}
                  //   dotsDisabled
                  //   buttonsDisabled
                  //   items={this.getListOfCustomerItems(customers)}
                  //   responsive={responsiveCustomers}
                  //   style={{ paddingBottom: '10px' }}
                  // />
                }
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps({
  customers,
  outsourcingServices: { overview, detail },
  services: { serviceList },
}) {
  return {
    customers,
    overview,
    detail,
    serviceList,
  };
}

export default connect(mapStateToProps, actions)(Outsourcing);
