import { Row, Col, Grid } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import footer_en from './en';
import footer_vi from './vi';
import footer_jp from './jp';
import axios from 'axios';

const skeleton = {
  'vi-VN': footer_vi,
  'en-US': footer_en,
  'ja-JP': footer_jp,
};

class Footer extends Component {
  displayName = Footer.name;
  constructor(props) {
    super(props);
    this.state = {
      footerPageData: skeleton[this.props.lang],
    };
  }

  changeLang(lang_code) {
    switch (lang_code) {
      case 'vi-VN':
        this.setState({ footerPageData: footer_vi });
        break;
      case 'en-US':
        this.setState({ footerPageData: footer_en });
        break;
      case 'ja-JP':
        this.setState({ footerPageData: footer_jp });
        break;
      default:
        this.setState({ footerPageData: footer_vi });
        break;
    }
  }

  componentDidMount() {
    this.changeLang(this.props.lang);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.changeLang(this.props.lang);
    }
  }

  handleEmailSubmit(e) {
    e.preventDefault();

    this.props.showModalContent('loading');
    axios
      .post('/api/subscribe', { email: e.target.subscribeUser.value })
      .then((response) => {
        if (response.data.status === 'ok') {
          this.props.showModalContent(
            'request-ok',
            'Thanks for registering with us'
          );
        } else {
          this.props.showModalContent(
            'failed',
            response.data.help[this.props.lang]
          );
        }
      })
      .catch((e) => {
        this.props.showModalContent(
          'request-failed',
          String(e).slice(0, 200) + '...'
        );
      });
  }

  render() {
    const { footerPageData } = this.state;
    return footerPageData === null ? (
      <div id="footer"></div>
    ) : (
      <div id="footer" className="footer-area section-padding-80-0">
        {/* Main Footer Area */}
        <div className="main-footer-area">
          <Grid>
            <Row className="justify-content-between">
              {/* Single Footer Widget Area */}
              <Col xs={12} sm={4} lg={2}>
                <div className="single-footer-widget mb-80">
                  {/* Widget Title */}
                  <h5 className="widget-title">
                    {footerPageData.company.header}
                  </h5>
                  {/* Footer Nav */}
                  <ul className="footer-nav">
                    {footerPageData.company.mainLinks.map((item, index) => (
                      <li key={index}>
                        <a href={item.link}>{item.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
              {/* Single Footer Widget Area */}
              <Col xs={12} sm={8} lg={10}>
                <div className="single-footer-widget mb-80">
                  {/* Widget Title */}
                  <h5 className="widget-title">
                    {footerPageData.subscription.header}
                  </h5>
                  <p className="p--small">
                    {footerPageData.subscription.paragraph}
                  </p>
                  {/* Newsletter Form */}
                  <form
                    onSubmit={(e) => {
                      this.handleEmailSubmit(e);
                    }}
                    className="nl-form"
                  >
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Mail"
                      id="subscribeUser"
                    />
                    <button type="submit">
                      <i className="fa fa-paper-plane" aria-hidden="true" />
                    </button>
                  </form>
                  {/* Social Info */}
                  <div className="social-info">
                    <a href="/" className="facebook">
                      <i className="fa fa-facebook" aria-hidden="true" />
                    </a>
                    <a href="/" className="twitter">
                      <i className="fa fa-twitter" aria-hidden="true" />
                    </a>
                    <a href="/" className="google-plus">
                      <i className="fa fa-google-plus" aria-hidden="true" />
                    </a>
                    <a href="/" className="instagram">
                      <i className="fa fa-instagram" aria-hidden="true" />
                    </a>
                    <a href="/" className="youtube">
                      <i className="fa fa-youtube" aria-hidden="true" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
        {/* Bottom Footer Area */}
        <div className="bottom-footer-area">
          <Grid>
            <Row>
              <Col xs={12}>
                {/* Copywrite Text */}
                <div className="copywrite-text">
                  <p>{footerPageData.contact.address}</p>
                  <p>{footerPageData.contact.telephone}</p>

                  <p className="mt-15">
                    Copyright © All rights reserved | This is made by{' '}
                    <a href="/" target="_blank">
                      R&D ITSC
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, actions)(Footer);
