import React, { Component } from 'react';
//import "./LanguageSelect.css";
import { connect } from 'react-redux';
import * as actions from '../actions';

const langItems = [
  {
    iconClass: 'sl-flag flag-usa',
    title: 'EN',
    value: 'en-US',
    pathway: 'en',
  },
  {
    iconClass: 'sl-flag flag-jp',
    title: '日本語',
    value: 'ja-JP',
    pathway: 'ja',
  },
  {
    iconClass: 'sl-flag flag-vn',
    title: 'VN',
    value: 'vi-VN',
    pathway: 'vi',
  },
];

class LanguageSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: 'EN',
    };
  }

  componentDidMount() {}

  onLanguageChange(item) {
    var nextLocation =
      '/' + item.value.split('-')[0] + window.location.pathname.slice(3);
    if (window.location.pathname !== nextLocation) {
      window.location.pathname = nextLocation;
    }
  }

  render() {
    var { lang } = this.props;
    return (
      <div className="nav-wrapper">
        <div className="sl-nav">
          <div style={{ flex: 1 }}>
            <ul>
              <li>
                <b>{lang}</b>{' '}
                <i className="fa fa-angle-down" aria-hidden="true"></i>
                <div className="triangle"></div>
                <ul>
                  {langItems.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        this.onLanguageChange(item);
                      }}
                      className="hoverLanguage"
                    >
                      <li>
                        <i className={item.iconClass}></i>{' '}
                        <span className="active">{item.value}</span>
                      </li>
                    </div>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      console.log(this.props.lang);
    }
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, actions)(LanguageSelect);
