import { GET_PRODUCT_CATEGORIES_L1, GET_PRODUCT_CATEGORIES_L2 } from "../actions/types";

export default function(state = {parent: {}, children: []}, action) {
  switch (action.type) {
    case GET_PRODUCT_CATEGORIES_L1:
      return action.payload || null;
    case GET_PRODUCT_CATEGORIES_L2:
      return action.payload;
    default:
      return state;
  }
}
