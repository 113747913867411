import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Carousel, Row, Grid } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import * as actions from '../../actions';

class AboutUsPage extends Component {
  displayName = AboutUsPage.name;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getAboutUs(this.props.lang);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lang !== prevProps.lang) {
      this.props.getAboutUs(this.props.lang);
    }
  }

  render() {
    const { info, history, structure } = this.props;
    return (
      <div className="wrapper--one-topic">
        <Grid id="aboutus">
          <Row id="company-info" className="home--overview mt-30">
            <Col xs={12}>
              <h3>&nbsp;{info.full_name || <Skeleton />}</h3>
              <div className="home--description">
                <p>{info.description || <Skeleton count={5} />}</p>
              </div>
            </Col>
          </Row>

          <Row id="company-history" className="home--history">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="home--history__carousel"
            >
              <Carousel className="carousel--about-us">
                {history.map((item, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <img alt="history data of company" src={item.image} />
                      <Carousel.Caption>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Col>

            {history.map((item, index) => {
              return (
                <Col
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  key={index}
                  className="home--history__list-all"
                >
                  <div className="component--history">
                    <div className="top">
                      <img
                        alt="history items"
                        src={item.image}
                        width="100%"
                        height="auto"
                      />
                    </div>
                    <div className="bottom">
                      <div className="bottom--h4">
                        <h4>{item.title}</h4>
                      </div>
                      <p className="p--small">{item.description}</p>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Grid>
        <div id="itsc-branching" className="home--branches">
          <Grid>
            <Row className="wrapper--branching">
              <Col xs={12} sm={6}>
                <div className="left">
                  <div>
                    <h3>{!structure[0] ? null : structure[0].tag_value}</h3>
                  </div>
                  {!structure[1] ? null : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: structure[1].tag_value,
                      }}
                    />
                  )}
                </div>
              </Col>

              <Col xs={12} sm={6} className="image--branching">
                <div className="right scale-in-out">
                  {!structure[2] ? null : (
                    <img
                      alt="itsc and idea group"
                      src={structure[2].tag_value}
                    />
                  )}
                </div>
              </Col>
              <div className="wrapper--decoration-branching">
                <div className="decoration-line--branching fade-in-top-down"></div>
                <div className="decoration-line--branching-1 fade-in-top-down"></div>
                <div className="decoration-line--branching-2 fade-in-top-down"></div>
              </div>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ aboutUs: { info, history, structure } }) {
  return {
    info,
    history,
    structure,
  };
}

export default connect(mapStateToProps, actions)(AboutUsPage);
