import { GET_ONE_POST } from "../actions/types";
function camelCase(input) {
  return input[0].toUpperCase() + input.slice(1);
}

function uniteSubcats(data) {
  var willBeUnion = [];
  [...data].map((item) => {
    willBeUnion = willBeUnion.concat(item.subcats);
    return item;
  });
  return willBeUnion;
}

var initialState = {
  lang: "vi-VN",
  perPage: 15,
  productList: [],
  oneProduct: {
    price: "",
    stock: "",
    bannerImage: "",
    status: "Draft",
    visibility: "Public",
    schedule: "Now",
    categorization: [],
    seoTags: [],
    imageList: [],
    multilingualInfo: {
      "vi-VN": {
        name: "",
        description: "",
        introduction: "",
        technical_information: "",
        other_information: "",
      },
      "en-US": {
        name: "",
        description: "",
        introduction: "",
        technical_information: "",
        other_information: "",
      },
      "ja-JP": {
        name: "",
        description: "",
        introduction: "",
        technical_information: "",
        other_information: "",
      },
    },
  },
  postList: [],
  onePost: {
    bannerImage: "",
    status: "Draft",
    visibility: "Public",
    schedule: "Now",
    categorization: [],
    seoTags: [],
    multilingualInfo: {
      "vi-VN": {
        title: "",
        description: "",
        content: "",
      },
      "en-US": {
        title: "",
        description: "",
        content: "",
      },
      "ja-JP": {
        title: "",
        description: "",
        content: "",
      }
    }
  },
  serviceList: [],
  oneService: {
    keyname: "",
    bannerImage: "",
    status: "Draft",
    visibility: "Public",
    schedule: "Now",
    multilingualInfo: {
      "vi-VN": {
        display_name: "",
        description: "",
        slogan: "",
        post: ""
      },
      "en-US": {
        display_name: "",
        description: "",
        slogan: "",
        post: ""
      },
      "ja-JP": {
        display_name: "",
        description: "",
        slogan: "",
        post: ""
      },
    }
  },
  postCategories: [],
  productCategories: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "CHANGE_FIELD":
      return action.payload.type === null
        ? { ...state, [action.payload.field]: action.payload.value }
        : !action.payload.lang
        ? {
          ...state,
          ["one" + camelCase(action.payload.type)]: {
            ...state["one" + camelCase(action.payload.type)],
            [action.payload.field]: action.payload.value,
          },
        } 
        : {
          ...state,
          ["one" + camelCase(action.payload.type)]: {
            ...state["one" + camelCase(action.payload.type)],
            multilingualInfo: {
              ...state["one" + camelCase(action.payload.type)].multilingualInfo,
              [action.payload.lang]: {
                ...state["one" + camelCase(action.payload.type)].multilingualInfo[action.payload.lang],
                [action.payload.field]: action.payload.value,
              },
            }
          },
        };

    case "CHANGE_SEO_TAGS":
      return {
        ...state,
        onePost: {
          ...state.onePost,
          seoTags: action.payload,
        },
      };

    case "GET_CATEGORIES":
      return action.payload.type === "post"
        ? {
            ...state,
            [action.payload.type + "Categories"]: action.payload.data.map(
              (item) => ({
                ...item,
                checked: false,
              })
            ),
          }
        : {
            ...state,
            [action.payload.type + "Categories"]: uniteSubcats(
              action.payload.data
            ),
          };

    ///////////////////////////////POST////////////////////////////

    case "RESET":
      return {
        ...state,
        ["one" + camelCase(action.payload.type)]: initialState[
          "one" + camelCase(action.payload.type)
        ],
      };

    case "GET_POSTS":
      return {
        ...state,
        postList: action.payload,
      };

    case GET_ONE_POST:
      return {
        ...state,
        onePost: {
          ...action.payload,
          seoTags: [],
          status: "Publish",
          visibility: "Public"
        },
      };

    //////////////////////////////////////////////////////////////////

    case "GET_PRODUCTS":
      return {
        ...state,
        productList: action.payload,
      };

    case "GET_ONE_PRODUCT":
      return {
        ...state,
        oneProduct: {
          ...action.payload.info,
          imageList: action.payload.images,
          status: "Publish",
          visibility: "Public"
        },
      };

    //////////////////////////////////////////////////////////////////

    case "GET_SERVICES":
      return {
        ...state,
        serviceList: action.payload,
      };

    case "GET_ONE_SERVICE":
      return {
        ...state,
        oneService: {
          ...state.oneService,
          ...action.payload.overview,
          status: "Publish",
          visibility: "Public"
        },
      };

    default:
      return state;
  }
}
