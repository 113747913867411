import React, { Component } from 'react';
import {
  Col,
  Grid,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Checkbox,
  Button,
} from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Helmet } from 'react-helmet';

function FieldGroup({ id, label, help, onChange, validationState, ...props }) {
  return (
    <FormGroup controlId={id} validationState={validationState}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl onChange={(event) => onChange(event)} {...props} />
    </FormGroup>
  );
}

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: null,
      isSubmitting: false,
    };
  }

  async handleSubmit(event) {
    event.preventDefault();

    var username = event.target.elements.username.value;
    var password = event.target.elements.password.value;
    if (username.length === 0) return;
    if (password.length <= 8) {
      return alert('PASSWORDS NEED TO BE MORE THEN 8 CHARACTERS');
    }
    this.setState({ isSubmitting: true });
    setTimeout(async () => {
      var result = await axios.post('/api/auth/local', {
        username: username,
        password: password,
      });

      if (result.data.status) {
        this.props.history.push('/');
      } else {
        this.setState({ validation: 'error' });
        this.setState({ isSubmitting: false });
      }
    }, 300);
  }

  handleSignInButton() {
    document.getElementById('lds-ripple-wrapper').style.opacity = 1;
  }

  closePanel() {
    document.getElementById('signInSignUp').style.display = 'none';
    document.getElementById('lds-ripple-wrapper').style.opacity = 0;
  }

  handleChange(event) {}

  async componentDidMount() {
    await this.props.fetchUser();
    if (!!this.props.auth) {
      this.props.history.push('/');
    }
  }

  render() {
    var { auth, route } = this.props;
    return !!auth ? null : (
      <Grid fluid>
        <Helmet>
          <title>ITSC Sign in</title>
          <meta property="og:title" content="ITSC Sign in" />
        </Helmet>
        <div className="author-wrapper">
          <Row className="author-panel">
            <Col xs={12}>
              <div className="sigin-in-form">
                <div className="sigin-title">
                  <h1>Sign in</h1>
                </div>
                <Form horizontal onSubmit={this.handleSubmit.bind(this)}>
                  <FieldGroup
                    id="username"
                    disabled={this.state.isSubmitting}
                    validationState={this.state.validation}
                    type="text"
                    label="Username"
                    placeholder="Enter username or email address"
                    onChange={(event) => {}}
                  />
                  <FieldGroup
                    id="password"
                    disabled={this.state.isSubmitting}
                    validationState={this.state.validation}
                    type="password"
                    label="Passwords"
                    placeholder="Enter password"
                    onChange={(event) => {}}
                  />
                  <FormGroup>
                    <Checkbox>Remember me</Checkbox>
                  </FormGroup>

                  <FormGroup>
                    <Button
                      disabled={this.state.isSubmitting}
                      type="submit"
                      className="btn--center"
                    >
                      {this.state.isSubmitting ? 'Authorizing' : 'Sign in'}
                    </Button>
                  </FormGroup>
                </Form>
                <div className="signin__bottom">
                  <ul>
                    <li>
                      <a
                        disabled={this.state.isSubmitting}
                        href={`/${route.lang.slice(0, 2)}/sign-up`}
                      >
                        Forgot passwords?
                      </a>
                    </li>
                    <li>
                      <a
                        disabled={this.state.isSubmitting}
                        href={`/${route.lang.slice(0, 2)}/sign-up`}
                      >
                        {' '}
                        Register members?
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Grid>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}

export default connect(mapStateToProps, actions)(SignIn);
