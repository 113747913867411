import {
  TEST,
  GET_POSTS,
  GET_ONE_POST,
  GET_ALL_CATEGORIES,
  GET_POST_BY_CATEGORY,
  GET_CUSTOMERS,
  GET_ALL_ABOUT_OUTSOURCING,
  GET_PRODUCT_BY_ID,
  GET_PRODUCTS_BY_CAT_ID,
  GET_ALL_ABOUT_ABOUTUS,
  GET_ALL_ABOUT_RD,
  GET_ALL_ABOUT_EDU,
  GET_PRODUCT_CATEGORIES_L1,
  GET_PRODUCT_CATEGORIES_L2,
  UPDATE_LANG,
  FETCH_USER,
} from './types';
import axios from 'axios';

export const justTest = () => async (dispatch, getState, api) => {
  const res = await api.get('/api');
  dispatch({ type: TEST, payload: res.data });
};

export const getPost = (page, lang) => async (dispatch, getState, api) => {
  const res = await api.get(
    `/api/posts?page=${!page ? 1 : page}${!lang ? '' : `&lang=${lang}`}`
  );
  dispatch({ type: GET_POSTS, payload: res.data });
};

export const getPostById = (postId, lang) => async (
  dispatch,
  getState,
  api
) => {
  const res = await api.get(
    `api/posts/${postId}${!lang ? '' : `?lang=${lang}`}`
  );
  dispatch({ type: GET_ONE_POST, payload: res.data });
};

export const getAllCategories = () => async (dispatch, getState, api) => {
  const res = await api.get('api/categories');
  dispatch({ type: GET_ALL_CATEGORIES, payload: res.data });
};

export const getPostByCategory = (categoryName, lang) => async (
  dispatch,
  getState,
  api
) => {
  const res = await api.get(
    `/api/posts?category=${categoryName}${!lang ? '' : `&lang=${lang}`}`
  );
  dispatch({ type: GET_POST_BY_CATEGORY, payload: res.data });
};

export const getListOfCustomer = () => async (dispatch, getState, api) => {
  const res = await api.get('/api/customers');
  dispatch({ type: GET_CUSTOMERS, payload: res.data });
};

export const getOutsourcingServices = (lang) => async (
  dispatch,
  getState,
  api
) => {
  const outsourcingService = await api.get(
    `/api/services/1${!lang ? '' : `?lang=${lang}`}`
  );
  dispatch({
    type: GET_ALL_ABOUT_OUTSOURCING,
    payload: outsourcingService.data,
  });
};

export const getServices = (serviceId, lang) => async (
  dispatch,
  getState,
  api
) => {
  const service = await api.get(
    `/api/services/${serviceId}${!lang ? '' : `?lang=${lang}`}`
  );
  dispatch({
    type: 'GET_SERVICE',
    payload: service.data,
  });
};

export const getAllServices = (lang) => async (dispatch, getState, api) => {
  const service = await api.get(
    `/api/services/${!lang ? '' : `?lang=${lang}`}`
  );
  dispatch({
    type: 'GET_ALL_SERVICES',
    payload: service.data,
  });
};

export const getProductById = (id, lang) => async (dispatch, getState, api) => {
  const product = await api.get(
    `/api/products/${id}${!lang ? '' : `?lang=${lang}`}`
  );
  dispatch({
    type: GET_PRODUCT_BY_ID,
    payload: product.data,
  });
};

export const getProductsByCatId = (catId, lang) => async (
  dispatch,
  getState,
  api
) => {
  const products = await api.get(
    `api/products?category=${catId}${!lang ? '' : `&lang=${lang}`}`
  );
  dispatch({
    type: GET_PRODUCTS_BY_CAT_ID,
    payload: products.data,
  });
};

export const getAboutUs = (lang) => async (dispatch, getState, api) => {
  const aboutUs = await api.get(
    `api/companyinfo/1${!lang ? '' : `?lang=${lang}`}`
  );

  dispatch({
    type: GET_ALL_ABOUT_ABOUTUS,
    payload: aboutUs.data,
  });
};

export const getRD = (lang) => async (dispatch, getState, api) => {
  const rd = await api.get(`api/services/3${!lang ? '' : `?lang=${lang}`}`);
  dispatch({
    type: GET_ALL_ABOUT_RD,
    payload: rd.data,
  });
};

export const getEdu = (lang) => async (dispatch, getState, api) => {
  const edu = await api.get(`api/services/2${!lang ? '' : `?lang=${lang}`}`);
  dispatch({
    type: GET_ALL_ABOUT_EDU,
    payload: edu.data,
  });
};

export const updateLang = (lang) => (dispatch) => {
  dispatch({
    type: UPDATE_LANG,
    payload: lang,
  });
};

export const getProductsL1 = (lang) => async (dispatch, getState, api) => {
  const catL1 = await api.get(
    `api/productcategories${!lang ? '' : `?lang=${lang}`}`
  );
  dispatch({
    type: GET_PRODUCT_CATEGORIES_L1,
    payload: catL1.data,
  });
};

export const getProductsL2 = (parentId, lang) => async (
  dispatch,
  getState,
  api
) => {
  const catL2 = await api.get(
    `api/productcategories/l2/${parentId}${!lang ? '' : `?lang=${lang}`}`
  );
  dispatch({
    type: GET_PRODUCT_CATEGORIES_L2,
    payload: catL2.data,
  });
};

export const updateNavigations = (navItem) => (dispatch) => {
  dispatch({
    type: 'NAV_POSITION',
    payload: navItem,
  });
};

export const fetchUser = () => async (dispatch, getState, api) => {
  const fetchUserRes = await api.get('api/current-user');
  //  console.log(fetchUserRes.data);
  dispatch({
    type: FETCH_USER,
    payload: fetchUserRes.data,
  });
};

export const showModalContent = (topic, content) => (dispatch) =>
  dispatch({
    type: 'SHOW_CONTENT',
    payload: { topic, content },
  });

export const hideModalContent = () => (dispatch) => {
  dispatch({
    type: 'HIDE_CONTENT',
    payload: null,
  });
};

// export const FETCH_USERS = 'fetch_users';
// export const fetchUsers = () => async (dispatch, getState, api) => {
//   const res = await api.get('/users');
//   dispatch({
//     type: FETCH_USERS,
//     payload: res,
//   });
// };

// export const FETCH_CURRENT_USER = 'fetch_current_user';
// export const fetchCurrentUser = () => async (dispatch, getState, api) => {
//   const res = await api.get('/current_user');
//   dispatch({
//     type: FETCH_CURRENT_USER,
//     payload: res,
//   });
// };

// export const FETCH_ADMINS = 'fetch_admins';
// export const fetchAdmins = () => async (dispatch, getState, api) => {
//   const res = await api.get('/admins');
//   dispatch({
//     type: FETCH_ADMINS,
//     payload: res,
//   });
// };
