const landing_en = {
  leftPanel: [
    'WE DELIVER',
    'HIGH QUALITY',
    'CAD/CAM/CAE',
    'OUTSOURCING SERVICES',
    'Also, we implement outstanding R&D activities as well as education, training programs to achieve our own great technological leap.',
    '/my-content/resources/ex/landing-en.svg',
  ],

  slideShowImageList: [
    '/my-content/resources/landingpage01.png',
    '/my-content/resources/landingpage02.png',
    '/my-content/resources/landingpage03.png',
    '/my-content/resources/engine.png',
  ],

  hookingQuestions: [
    {
      description: 'Would like to collaborate with us?',
      link: '',
      position: 'telephone',
      location: 'tel:12312312312',
    },
    {
      description: 'See how is our outsourcing ability?',
      link: '',
      position: 'outsourcingPage',
      location: '',
    },
    {
      description: 'Find out opportunities?',
      link: '',
      position: 'redirect',
      location: '/en/opportunities',
    },
    {
      description: 'Get our latest news over email?',
      link: '',
      position: 'footer',
      location: '',
    },
  ],
  callToAction: {
    buttonName: 'CONTACT US',
    textboxPlaceHolder: 'What are you looking for?',
  },
};

export default landing_en;
