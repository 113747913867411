import { GET_PRODUCT_BY_ID } from '../actions/types';

export default function (
  state = { info: {}, images: [], catalogues: [] },
  action
) {
  switch (action.type) {
    case GET_PRODUCT_BY_ID:
      return action.payload;
    default:
      return state;
  }
}
