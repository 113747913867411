import React, { Component, useState, useEffect } from 'react';
import {
  Grid,
  Row,
  Col,
  ButtonToolbar,
  Button,
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
  HelpBlock,
  Panel,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { registerForm } from './registerForm';
import axios from 'axios';
import errReport from '../../components/insidemodal/errReport';
import { Helmet } from 'react-helmet';

const stepData = [
  {
    id: 1,
    title: {
      'vi-VN': 'Điền thông tin vào phiếu để đăng ký thành viên',
      'en-US': 'Fill in your information to register as a member',
      'ja-JP': 'Fill in your information to register as a member',
    },
    component: (props) => <TableFilling {...props} />,
  },
  {
    id: 2,
    title: {
      'vi-VN': 'Xác nhận lại thông tin trước khi đăng ký',
      'en-US': 'Please review your information and confirm it',
      'ja-JP': 'Please review your information and confirm it',
    },
    component: (props) => <TableFilling isReview={true} {...props} />,
  },
  {
    id: 3,
    title: {
      'vi-VN': 'Xác thực tài khoản của bạn bằng cách kiểm tra địa chỉ email',
      'en-US': 'Please check your email to complete verification process',
      'ja-JP': 'Please check your email to complete verification process',
    },
    component: (props) => <CompleteRegister {...props} />,
  },
];

const skeleton = {
  back: {
    'vi-VN': 'Quay lại',
    'en-US': 'Back',
    'ja-JP': 'Back',
  },
  continue: {
    'vi-VN': 'Tiếp tục',
    'en-US': 'Next',
    'ja-JP': 'Next',
  },
  title: {
    'vi-VN': 'Đăng ký thành viên ITSC',
    'en-US': 'Register Member Process',
    'ja-JP': 'Register Member Process',
  },
  thanks: {
    'vi-VN': 'Cảm ơn đã đăng ký thành viên với ITSC',
    'en-US': 'Thanks for registering',
    'ja-JP': 'Thanks for registering',
  },
};

function FieldGroup({ id, label, help, validationState, ...props }) {
  return (
    <Row>
      <Col xsHidden sm={2}></Col>
      <Col xs={12} sm={8}>
        <FormGroup controlId={id} validationState={validationState}>
          <ControlLabel> {label}</ControlLabel>
          <FormControl {...props} />
          {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>
      </Col>
      <Col xsHidden sm={2}></Col>
    </Row>
  );
}

function CompleteRegister(props) {
  return (
    <div>
      <h4>{skeleton.thanks[props.lang]}</h4>
      <p>{props.finalContent}</p>
    </div>
  );
}

/////////////////////////////////////////////////////////////////////////////////////////////

function TableFilling(props) {
  const formKeys = registerForm.map((item) => item.id);
  var { validation } = props;
  var [regis, setRegis] = useState(props.data || {});
  var { lang } = props;

  const validate = (e) => {
    formKeys.forEach((element) => {
      handleFieldValidation(e.target[element]);
    });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    props.onOK(regis);
  };

  const handleFieldChange = (e) => {
    e.preventDefault();
    setRegis(Object.assign({}, regis, { [e.target.id]: e.target.value }));
  };

  const handleFieldValidation = (target) => {
    if (!target.value || target.value.length < 3) {
      validation = Object.assign({}, validation, {
        [target.id]: { status: 'error', help: errReport.missing },
      });
      props.onValidationChange(validation);
    } else {
      validation = Object.assign({}, validation, {
        [target.id]: { status: null, help: null },
      });
      props.onValidationChange(validation);
    }
  };

  var isReview = props.isReview || false;
  return (
    <div className="mt-30 mb-30">
      <Form onSubmit={(e) => handleSubmitForm(e)}>
        {registerForm.map((item, index) => (
          <FieldGroup
            {...item}
            validationState={
              validation[item.id] ? validation[item.id].status : null
            }
            onChange={(e) => {
              handleFieldChange(e);
            }}
            onBlur={(e) => {
              e.preventDefault();
              handleFieldValidation(e.target);
            }}
            value={regis[item.id] || ''}
            help={
              !validation[item.id]
                ? null
                : !validation[item.id].help
                ? null
                : validation[item.id].help[lang]
            }
            key={index}
            disabled={isReview}
            lang={lang}
            label={item.label[lang]}
            placeholder={item.placeholder[lang]}
          />
        ))}
        <button
          id="submit-form-btn"
          type="submit"
          style={{ display: 'none' }}
        />
      </Form>
    </div>
  );
}

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      confirmed: {},
      feedbackValidation: {},
      finalContent: null,
      isSubmitting: false,
    };
  }

  handleCheckData(data) {
    axios.post('/api/auth/check-account', data).then((response) => {
      if (response.data.valid) {
        this.setState({
          activeIndex: this.state.activeIndex + 1,
          confirmed: data,
        });
      } else {
        this.setState({
          feedbackValidation: {
            ...this.state.feedbackValidation,
            ...response.data.feedbackValidation,
          },
        });
      }
    });
  }

  handleSubmitRegister(data) {
    this.setState({ isSubmitting: true });
    axios.post('/api/auth/register', data).then((response) => {
      this.setState({
        activeIndex: this.state.activeIndex + 1,
        finalContent: response.data.msg,
      });
    });
  }

  onNext() {
    var { activeIndex } = this.state;

    if (activeIndex === 0) {
      document.getElementById('submit-form-btn').click();
    }

    if (activeIndex === 1) {
      this.handleSubmitRegister(this.state.confirmed);
    }
  }

  onBack() {
    var { activeIndex } = this.state;

    if (activeIndex > 0) {
      this.setState({ activeIndex: activeIndex - 1 });
    }
  }

  renderSteps(data, active, lang) {
    const colVar = 12 / data.length;
    return stepData.map((item, index) => {
      return (
        <Col key={index} xs={12} sm={colVar}>
          <div
            className={
              index === active ? 'card__step-title--active' : 'card__step-title'
            }
          >
            <h4>
              {index + 1}/{item.title[lang]}
            </h4>
          </div>
        </Col>
      );
    });
  }

  render() {
    var {
      activeIndex,
      feedbackValidation,
      confirmed,
      finalContent,
      isSubmitting,
    } = this.state;
    var { lang } = this.props;
    var DynamicComponent = stepData[activeIndex].component;
    return (
      <Grid className="mt-80 mb-80">
        <Helmet>
          <title>{skeleton.title[lang]}</title>
          <meta property="og:title" content={skeleton.title[lang]} />
          <meta property="og:description" content={skeleton.title[lang]} />
        </Helmet>
        <Row className="mt-15 mb-15">
          <Col xs={12}>
            <h1>{skeleton.title[lang]}</h1>
            <hr />
          </Col>
        </Row>
        <Row>{this.renderSteps(stepData, activeIndex, lang)}</Row>
        <Row>
          <Col xs={12}>
            <Panel bsStyle="info" className="mt-15">
              <Panel.Heading> </Panel.Heading>
              <Panel.Body>
                <DynamicComponent
                  finalContent={finalContent}
                  onOK={(data) => {
                    this.handleCheckData(data);
                  }}
                  validation={feedbackValidation}
                  onValidationChange={(data) => {
                    this.setState({ feedbackValidation: data });
                  }}
                  data={confirmed}
                  lang={lang}
                />
              </Panel.Body>
              <Panel.Footer></Panel.Footer>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {activeIndex < 2 && (
              <ButtonToolbar style={{ float: 'right' }}>
                <Button
                  bsSize="large"
                  bsStyle="primary"
                  disabled={isSubmitting}
                  onClick={() => {
                    this.onBack();
                  }}
                >
                  {skeleton.back[lang]}
                </Button>
                <Button
                  bsSize="large"
                  disabled={isSubmitting}
                  bsStyle="warning"
                  onClick={() => {
                    this.onNext();
                  }}
                >
                  {skeleton.continue[lang]}
                </Button>
              </ButtonToolbar>
            )}
          </Col>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default {
  component: connect(mapStateToProps, actions)(SignUp),
};
