import React from 'react';

function Done(props) {
  return (
    <div className="section-padding-80">
      <i
        className="fa fa-check-circle"
        style={{
          fontSize: 100,
          color: 'green',
          display: 'block',
          margin: 'auto',
          textAlign: 'center',
        }}
      ></i>
      <h3 style={{ textAlign: 'center' }}>SUCCESS</h3>
      <h5 style={{ textAlign: 'center' }}>{props.content}</h5>
    </div>
  );
}

function DoneError(props) {
  return (
    <div className="section-padding-80">
      <i
        className="fa fa-ban"
        style={{
          fontSize: 100,
          color: 'red',
          display: 'block',
          margin: 'auto',
          textAlign: 'center',
        }}
      ></i>
      <h3 style={{ textAlign: 'center' }}>FAILED</h3>
      <h5 style={{ textAlign: 'center' }}>{props.content}</h5>
    </div>
  );
}

function Unknown() {
  return (
    <div>
      <h5>AMBIGUOUS</h5>
    </div>
  );
}

function Loading() {
  return (
    <div>
      <div className="lds-roller loader--padding">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export { Done, DoneError, Unknown, Loading };
