import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';

import {
  Panel,
  InputGroup,
  FormControl,
  Button,
  Glyphicon,
} from 'react-bootstrap';

class SearchOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchOverlayPageData: null,
      testResults: null,
      enabledSearchResults: false,
    };
  }

  changeLang(lang_code) {
    switch (lang_code) {
      case 'vi-VN':
        break;
      case 'en-US':
        break;
      case 'ja-JP':
        break;
      default:
        break;
    }
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
    }
  }

  renderResultLists(resultArray) {
    if (!Array.isArray(resultArray)) return <div>No results match</div>;
    return resultArray.map((item, index) => (
      <li key={index}>
        <a href={item.link}>
          <div>
            <h4>{item.title}</h4>
          </div>
          <hr />
        </a>
      </li>
    ));
  }

  fakeAPI(data) {
    const dataYeah = [
      {
        title: 'Oops...Sorry. This search service is not ready yet!',
        description: 'This search service is not ready yet',
        link: '/vi',
      },
    ];

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(dataYeah);
      }, 1000);
    });
  }

  renderSearchResults() {
    const { testResults } = this.state;
    return testResults === null ? (
      <div className="lds-roller loader--padding">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    ) : (
      <div>
        <ul>{this.renderResultLists(testResults)}</ul>
      </div>
    );
  }

  async startSearching(data) {
    const result = await this.fakeAPI(data);
    this.setState({ testResults: result });
  }

  handleChange(e) {
    const searchWords = e.target.value;
    if (this.state.enabledSearchResults) {
      this.setState({ enabledSearchResults: false });
      this.setState({ testResults: null });
    }
    clearTimeout(this.inputTimer);
    if (!searchWords) return;
    this.inputTimer = setTimeout(() => {
      this.startSearching(searchWords);
      this.setState({ enabledSearchResults: true });
    }, 500);
  }

  render() {
    return !this.props.enabled ? null : (
      <div className="overlay">
        <Panel className="panel--shadow">
          <Panel.Body>
            <InputGroup>
              <InputGroup.Button>
                <Button
                  onClick={() => {
                    this.setState({ enabledSearchResults: false });
                    this.setState({ testResults: null });
                    this.props.onCloseSearchClick();
                  }}
                  bsStyle="danger"
                >
                  <i className="fa fa-times"></i>
                </Button>
              </InputGroup.Button>
              <FormControl
                placeholder="Enter text... "
                type="text"
                onChange={(event) => this.handleChange(event)}
              />
            </InputGroup>
            <div className="overlay--searchResults">
              {this.state.enabledSearchResults && this.renderSearchResults()}
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

function mapStateToProps({ lang }) {
  return {
    lang,
  };
}

export default connect(mapStateToProps, actions)(SearchOverlay);
