import React from "react";
import { Col } from "react-bootstrap";


var skeleton = {
  _customer: {
    "vi-VN": "KHÁCH HÀNG",
    "en-US": "CUSTOMERS",
    "ja-JP": "お客様"
  },
  _market: {
    "vi-VN": "THỊ TRƯỜNG",
    "en-US": "MARKET TARGETS",
    "ja-JP": "市場"
  },
  _staff: {
    "vi-VN": "NHÂN VIÊN",
    "en-US": "ENGINEERS",
    "ja-JP": "エンジニア"
  }
};

class OneRunningStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: 0
    };
  }

  turnItOn(endValue) {
    var { duration } = this.props;
    this.importantInterval = setInterval(() => {
      var { number } = this.state;
      number++;
      if (number >= endValue) clearInterval(this.importantInterval);
      this.setState({ number: number });
    }, duration);
  }

  componentDidUpdate(prevProps){
      if(prevProps.enable !== this.props.enable){
          if(this.props.enable){
              this.turnItOn(this.props.endValue);
          }
      }
  }

  render() {
    return (
      <Col xs={12} sm={4} md={4} lg={4}>
        <div className="running-stats-wrapper">
          <h1 className="running-stats-number">{this.state.number}</h1>
          <h5 className="alignTextCenter">{this.props.label}</h5>
        </div>
      </Col>
    );
  }
}

export default function RunningStatistics(props) {
  var lang = props.lang || "en-US";
  var enable = props.enable || false;

  return (
    <div id="running-stats">
      <OneRunningStatistics
        label={skeleton._customer[lang]}
        duration={1}
        endValue={150}
        enable={enable}
      />
      <OneRunningStatistics  enable={enable} label={skeleton._market[lang]} duration={1} endValue={5}/>
      <OneRunningStatistics  enable={enable} label={skeleton._staff[lang]} duration={1} endValue={70} />
    </div>
  );
}
