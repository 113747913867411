export default function (
  state = { oneService: { overview: {}, detail: [] }, serviceList: [] },
  action
) {
  switch (action.type) {
    case "GET_SERVICE":
      return {
        ...state,
        oneService: action.payload,
      };
    case "GET_ALL_SERVICES":
      return {
        ...state,
        serviceList: action.payload,
      };
    default:
      return state;
  }
}
