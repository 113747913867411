import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
var skeleton = {
  btnName: {
    'vi-VN': 'Xem thêm',
    'en-US': 'Read more',
    'ja-JP': '続きを読む',
  },
};

export default class ProductItemPortrait extends Component {
  render() {
    var lang = this.props.lang || 'ja-JP';
    var { title, description, imgSrc, path, views } = this.props;
    return (
      <div className="wrapper--product-item">
        <div className="wrapper--image-product-item">
          {!imgSrc ? (
            <Skeleton width={190} height={135} />
          ) : (
            <img alt="useful signal" src={imgSrc} />
          )}
        </div>
        <div className="title--product-item">
          <h6>{title || <Skeleton />}</h6>
        </div>
        <div className="description--product-item">
          <p>{description || <Skeleton count={4} />}</p>
        </div>
        <div className="widget--product-item">
          <div className="wrapper--product-read-more">
            <div className="button--read-more-gradient mt-15 mb-15">
              <a href={path}>
                <span>{skeleton.btnName[lang]} ></span>
              </a>
            </div>
          </div>
          <div className="wrapper--product-views">
            <p>
              {views}
              <i className="fa fa-eye"></i>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
