import { GET_PRODUCTS_BY_CAT_ID } from "../actions/types";

export default function (state = { parent: {}, children: [] }, action) {
  switch (action.type) {
    case GET_PRODUCTS_BY_CAT_ID:
      return action.payload;
    case "GET_PRODUCTS_BY_NAME":
      return action.payload;
    default:
      return state;
  }
}
