import React from "react";
import {Badge} from 'react-bootstrap';

export default function ProdItem(props){

    return (
        <div className={props.needShadow ? "custom-panel-2 common-shadow" : "custom-panel-2"}>
            <div className="product-manufacturer">
            </div>
            <div className="product-views">
                <Badge>12</Badge>
            </div>
            <div className="panel-image">
                <img alt="product" src={props.imgSrc || ""}/>
            </div>
            <div className="panel-detail">
                <h6>
                    {props.title}
                </h6>
                <p className="p--small">
                    {props.description}
                </p>
                <div className="more-hook">
                    <i className="fa fa-angle-right"></i>
                </div>
            </div>
        </div>
    );
}