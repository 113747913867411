const landing_jp = {
  leftPanel: [
    '高品質の',
    'CAD-CAM-CAE',
    'アウトソーシングサービス',
    'を提供します',
    'また、優れた研究開発活動と教育、トレーニングプログラムを実施して、独自の大きな技術的飛躍を実現しています。',
    '/my-content/resources/ex/landing-jp.svg',
  ],
  slideShowImageList: [
    '/my-content/resources/landingpage01.png',
    '/my-content/resources/landingpage02.png',
    '/my-content/resources/landingpage03.png',
    '/my-content/resources/engine.png',
  ],

  hookingQuestions: [
    {
      description: '私たちと協力してみませんか？',
      link: '',
      position: 'telephone',
      location: 'tel:12312312312',
    },
    {
      description: 'アウトソーシング能力はどうですか？',
      link: '',
      position: 'outsourcingPage',
      location: '',
    },
    {
      description: '機会を見つけますか？',
      link: '',
      position: 'redirect',
      location: '/ja/opportunities',
    },
    {
      description: 'メールで最新ニュースを入手しますか？',
      link: '',
      position: 'footer',
      location: '',
    },
  ],
  callToAction: {
    buttonName: 'お問い合わせ',
    textboxPlaceHolder: '何を探していますか？',
  },
};

export default landing_jp;
