const registerForm = [
  {
    id: 'email',
    label: { 'vi-VN': 'Email', 'en-US': 'Email', 'ja-JP': 'Email' },
    type: 'email',
    required: true,
    placeholder: {
      'vi-VN': 'vd: abc123@gmail.com',
      'en-US': 'exp: abc123@gmail.com',
      'ja-JP': 'exp: abc123@gmail.com',
    },
  },
  {
    id: 'confirmEmail',
    label: {
      'vi-VN': 'Xác nhận email',
      'en-US': 'Confirm email',
      'ja-JP': 'Email',
    },
    type: 'email',
    required: true,
    placeholder: {
      'vi-VN': 'vd: abc123@gmail.com',
      'en-US': 'exp: abc123@gmail.com',
      'ja-JP': 'exp: abc123@gmail.com',
    },
  },
  {
    id: 'loginId',
    label: {
      'vi-VN': 'Tên đăng nhập',
      'en-US': 'Login username',
      'ja-JP': 'Login username',
    },
    required: true,
    placeholder: {
      'vi-VN': 'vd: abc456',
      'en-US': 'exp: abc456',
      'ja-JP': 'exp: abc456',
    },
  },
  {
    id: 'pwd',
    label: { 'vi-VN': 'Mật khẩu', 'en-US': 'Passwords', 'ja-JP': 'Passwords' },
    type: 'password',
    required: true,
    placeholder: {
      'vi-VN': 'Mật khẩu phải dài hơn 8 ký tự',
      'en-US': 'Passwords must be longer than 8 characters',
      'ja-JP': 'Passwords must be longer than 8 characters',
    },
  },
  {
    id: 'confirmPwd',
    label: {
      'vi-VN': 'Xác nhận mật khẩu',
      'en-US': 'Confirm passwords',
      'ja-JP': 'Confirm passwords',
    },
    type: 'password',
    required: true,
    placeholder: {
      'vi-VN': 'Mật khẩu phải dài hơn 8 ký tự',
      'en-US': 'Passwords must be longer than 8 characters',
      'ja-JP': 'Passwords must be longer than 8 characters',
    },
  },
  {
    id: 'name',
    label: { 'vi-VN': 'Họ và Tên', 'en-US': 'Full name', 'ja-JP': 'Full name' },
    type: 'text',
    required: true,
    placeholder: {
      'vi-VN': 'vd: Nguyen Van A',
      'en-US': 'exp: Johnson Staham',
      'ja-JP': 'exp: Johnson Staham',
    },
  },
  {
    id: 'phoneNumber',
    type: 'text',
    label: {
      'vi-VN': 'Số điện thoại',
      'en-US': 'Phone number',
      'ja-JP': 'Phone number',
    },
    required: true,
    placeholder: {
      'vi-VN': '0905457888',
      'en-US': '123188994',
      'ja-JP': '88756465899',
    },
  },
  {
    id: 'viCompany',
    label: {
      'vi-VN': 'Tên công ty bằng tiếng việt',
      'en-US': 'Vietnamese Company Name',
      'ja-JP': 'Vietnamese Company Name',
    },
    type: 'text',
    required: true,
    placeholder: {
      'vi-VN': 'vd: Công ty TNHH ABC',
      'en-US': 'exp: Công ty TNHH ABC',
      'ja-JP': 'exp:Công ty TNHH ABC',
    },
  },
  {
    id: 'enCompany',
    label: {
      'vi-VN': 'Tên công ty bằng tiếng anh',
      'en-US': 'English Company Name',
      'ja-JP': 'English Company Name',
    },
    type: 'text',
    required: true,
    placeholder: {
      'vi-VN': 'vd: ABC JOINT STOCK COMPANY',
      'en-US': 'exp: ABC JOINT STOCK COMPANY',
      'ja-JP': 'exp:ABC JOINT STOCK COMPANY',
    },
  },
];

export { registerForm };
