import productMenus from './productMenus';

const navigationItems = [
  {
    title: ['Home', 'Trang Chủ', 'ホームページ'],
    path: '/',
    class: '',
  },
  {
    title: ['Products', 'Sản phẩm', 'アウトソーシング'],
    path: '/all-products',
    class: '',
    dropdown: true,
  },
  {
    title: ['Services', 'Dịch vụ', 'アウトソーシング'],
    path: '/services',
    class: '',
    dropdown: true,
    children: [
      {
        title: [
          'SUPPORT DESIGN SERVICE',
          'DỊCH VỤ HỖ TRỢ THIẾT KẾ',
          '機械設計製図オフショア受託​​​​​​​',
        ],
        path: '/services/support-design',
        class: 'largescreenNavItemHidden',
      },
      {
        title: [
          'CREATE SHEETMETAL DATA',
          'TẠO DỮ LIỆU SHEETMETAL',
          '板金展開・プラント関連受託',
        ],
        path: '/services/create-sheetmetal-data',
        class: 'largescreenNavItemHidden',
      },
      {
        title: [
          'ANALYSIS AND SIMULATIONS',
          'PHÂN TÍCH VÀ MÔ PHỎNG',
          '解析・シミュレーション支援',
        ],
        path: '/services/analysis-and-simulations',
        class: 'largescreenNavItemHidden',
      },
      {
        title: [
          'MOLD DESIGN AND SUPPORT',
          'HỖ TRỢ VÀ THIẾT KẾ KHUÔN',
          '金型設計製作・工業設計支援',
        ],
        path: '/services/mold-design-and-support',
        class: 'largescreenNavItemHidden',
      },
    ],
  },
  // {
  //     title: ['Activities', 'Hoạt động', '活動'],
  //     dropdown: true,
  //     class: 'largescreenNavItemHidden noPaddingRight',
  //     children: [
  //         {
  //             title: ['R&D','R&D','研究開発'] ,
  //             path: '/home/rd',
  //             class: 'largescreenNavItemHidden'
  //         },
  //     ]
  // },
  {
    title: ['News', 'Tin Tức', 'ニュース'],
    path: '/news',
    class: 'largescreenNavItemHidden',
  },
  {
    title: ['Opportunities', 'Tuyển dụng', '機会'],
    path: '/opportunities',
    class: 'largescreenNavItemHidden',
  },
  {
    title: ['More', 'Mở rộng', 'もっと'],
    dropdown: true,
    isExtended: true,
    class: 'largescreenNavMore noPaddingRight',
    path: 'none',
    children: [
      {
        title: ['News', 'Tin Tức', 'ニュース'],
        path: '/news',
        class: 'largescreenNavItemHidden',
      },
      {
        title: ['Opportunities', 'Tuyển dụng', '機会'],
        path: '/opportunities',
        class: 'largescreenNavItemHidden',
      },
    ],
  },
];

function convertLangToId(lang) {
  var id = 1;
  switch (lang) {
    case 'en-US':
      id = 0;
      break;
    case 'vi-VN':
      id = 1;
      break;
    case 'ja-JP':
      id = 2;
      break;

    default:
      id = 1;
      break;
  }
  return id;
}

function getChildrenMenusOfProducts(langId, lang) {
  return productMenus[langId]
    .filter((item) => item.id !== 1)
    .map((item, index) => {
      if (item.subcats.length === 0) {
        return {
          title: item.display_name,
          path: '/' + lang.split('-')[0] + (item.path == null ? '' : item.path),
          class: '',
        };
      } else {
        return {
          title: item.display_name,
          path: '/' + lang.split('-')[0] + (item.path == null ? '' : item.path),
          class: '',
          dropdown: true,
          children: item.subcats.map((subitem, subindex) => {
            return {
              ...subitem,
              title: subitem.display_name,
              path:
                '/' +
                lang.split('-')[0] +
                (subitem.path == null ? '' : subitem.path),
              class: '',
            };
          }),
        };
      }
    });
}

function getMenus(lang) {
  const langId = convertLangToId(lang);

  return navigationItems.map((item, index) => {
    if (item.dropdown && item.title[0] !== 'Products') {
      return {
        ...item,
        path: '/' + lang.split('-')[0] + item.path,
        title: item.title[langId],
        children: item.children.map((subitem, id) => {
          if (subitem.dropdown) {
            return {
              ...subitem,
              path: '/' + lang.split('-')[0] + subitem.path,
              title: subitem.title[langId],
              children: subitem.children.map((subsubitem, subid) => ({
                ...subsubitem,
                path: '/' + lang.split('-')[0] + subsubitem.path,
                title: subsubitem.title[langId],
              })),
            };
          } else
            return {
              ...subitem,
              path: '/' + lang.split('-')[0] + subitem.path,
              title: subitem.title[langId],
            };
        }),
      };
    } else if (item.dropdown && item.title[0] === 'Products') {
      return {
        ...item,
        path: '/' + lang.split('-')[0] + item.path,
        title: item.title[langId],
        children: getChildrenMenusOfProducts(langId, lang),
      };
    } else
      return {
        ...item,
        path: '/' + lang.split('-')[0] + item.path,
        title: item.title[langId],
      };
  });
}

export default getMenus;
