import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
var skeleton = {
  btnName: {
    'vi-VN': 'Xem thêm',
    'en-US': 'Read more',
    'ja-JP': '続きを読む',
  },
};

export default class ProductItemLandscape extends Component {
  render() {
    var lang = this.props.lang || 'ja-JP';
    var { title, description, imgSrc, path, views } = this.props;
    return (
      <div className="wrapper--product-item-2">
        <Row>
          <Col xs={12} sm={4} md={3} className="wrapper--image-product-item-2">
            {!imgSrc ? (
              <Skeleton width={190} height={135} />
            ) : (
              <img alt="meaninful yeah" src={imgSrc} />
            )}
          </Col>
          <Col xs={12} sm={8} md={9}>
            <div className="title--product-item-2">
              <h6>{title || <Skeleton />}</h6>
            </div>
            <div className="description--product-item-2">
              <p>{description || <Skeleton count={4} />}</p>
            </div>
            <div className="widget--product-item-2">
              <div className="wrapper--product-read-more">
                <div className="button--read-more-gradient mt-15 mb-15">
                  <a href={path}>
                    <span>{skeleton.btnName[lang]} ></span>
                  </a>
                </div>
              </div>
              <div className="wrapper--product-views">
                <p>
                  {views} <i className="fa fa-eye"></i>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
