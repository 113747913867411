const footer_en = {
  company: {
    header: 'Company',
    mainLinks: [
      {
        link: '/en',
        title: 'About',
      },
      {
        link: '/vi/opportunities',
        title: 'Careers',
      },
      {
        link: '/en',
        title: 'Contact',
      },
      {
        link: '/en',
        title: 'Terms of service',
      },
      {
        link: '/en',
        title: 'Privacy policy',
      },
      {
        link: '/en/news',
        title: 'Blog',
      },
    ],
  },
  subscription: {
    header: 'Subscribe Newsletter',
    paragraph:
      'Subscribe to our email newsletter for useful tips and valuable resources.',
  },
  contact: {
    address:
      'Address: 4th Floor, 69 Quang Trung St, Hai Chau Dist, Da Nang City, Vietnam',
    telephone:
      'Tel: (+84) 236 3539 125  I  Fax: (+84) 236 3539 125  I  Email: info@itsctech-vn.com',
  },
};

export default footer_en;
