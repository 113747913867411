import { GET_POSTS, GET_POST_BY_CATEGORY } from '../actions/types';

export default function(state=[], action){
    switch(action.type){
        case GET_POSTS:
            return state.concat(action.payload);
        case GET_POST_BY_CATEGORY:
            return action.payload;
        default:
            return state;
    }
}