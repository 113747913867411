import React from 'react';

const skeleton = {
  notfound: {
    'vi-VN': 'Không thể tìm thấy trang này',
    'en-US': 'The page you are looking for is not available',
    'ja-JP': 'お探しのページはありません',
  },
};

const NotFoundPage = ({ staticContext = {}, route }) => {
  staticContext.notFound = true;
  return (
    <div className="wrapper--not-found">
      <h1>{skeleton.notfound[route.lang]}</h1>
      <img src="/my-content/resources/404.gif" />
    </div>
  );
};

export default {
  component: NotFoundPage,
};
