import React, { Component } from 'react';
//import { Row } from "react-bootstrap";
import { connect } from 'react-redux';
import LandingPage from '../pages/landing/LandingPage';
import AboutUsPage from '../pages/aboutus/AboutUsPage';
import { Helmet } from 'react-helmet';
import Outsourcing from '../pages/outsourcing/Outsourcing';
import RAndD from '../pages/rd/RAndD';
import * as actions from '../actions';

const skeleton = {
  pageTitle: {
    'vi-VN': 'CÔNG TY CỔ PHẦN GIẢI PHÁP KỸ THUẬT Ý TƯỞNG',
    'ja-JP': 'IDEA TECHNOLOGY SOLUTIONS JOINT STOCK COMPANY',
    'en-US': 'IDEA TECHNOLOGY SOLUTIONS JOIN STOCK COMPANY',
  },
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolling: 'Stop',
      language: 'en',
      runningStatsEnabled: false,
    };
    this.initTimeout = '';
    this.lang = this.props.match.params.language || 'ambiguous';
  }

  initPosition(pos) {
    var scrollPosition = '';
    switch (pos.position) {
      case 'landing':
        scrollPosition = 'landingPage';
        break;
      case 'aboutus':
        scrollPosition = 'aboutus';
        break;
      case 'outsourcing':
        scrollPosition = 'outsourcingPage';
        break;
      case 'rd':
        scrollPosition = 'rdPage';
        break;
      case 'education':
        scrollPosition = 'educationPage';
        break;
      case 'redirect':
        this.props.history.push(pos.location);
        break;
      default:
        break;
    }
    const element = document.getElementById(scrollPosition);
    if (element === null) return;
    const offset = 75;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  /////// effect functions
  runningStatsEffect() {
    var runningElement = document.getElementById('running-stats');
    if (runningElement === null) return;
    var runningElementOffsetScrollPosTop = runningElement.getBoundingClientRect()
      .top;
    var windowHeight = window.innerHeight;
    if (runningElementOffsetScrollPosTop < windowHeight) {
      if (!this.state.runningStatsEnabled) {
        this.setState({ runningStatsEnabled: true });
      }
    }
  }

  fadingEffect(elementArray) {
    elementArray.forEach((element) => {
      var runningElement = document.getElementById(element);
      if (runningElement === null) return;
      var runningElementOffsetScrollPosTop = runningElement.getBoundingClientRect()
        .top;
      var windowHeight = window.innerHeight;
      if (runningElementOffsetScrollPosTop < windowHeight) {
        if (!runningElement.classList.contains('fade-in')) {
          runningElement.classList.add('fade-in');
        }
      }
    });
  }

  ////////////////////////

  handleScroll(event) {
    if (!this.state.runningStatsEnabled) {
      this.runningStatsEffect();
    }
    this.fadingEffect([
      'company-info',
      'company-history',
      'itsc-branching',
      'outsourcingPage',
      'rdPage',
    ]);
  }

  transparentizeComponents(componentArray) {
    componentArray.map((component) => {
      document.getElementById(component).style.opacity = 0;
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
    this.transparentizeComponents([
      'company-info',
      'company-history',
      'itsc-branching',
      'outsourcingPage',
      'rdPage',
    ]);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
    clearTimeout(this.initTimeout);
  }

  render() {
    var { lang } = this.props;

    return (
      <div>
        <Helmet>
          <title>{skeleton.pageTitle[this.props.lang]}</title>
          <meta
            property="og:title"
            content={skeleton.pageTitle[this.props.lang]}
          />

          <meta property="og:description" content="" />
        </Helmet>
        <LandingPage lang={lang} history={this.props.history} />
        <AboutUsPage lang={lang} />
        <Outsourcing
          lang={lang}
          enableRunningStats={this.state.runningStatsEnabled}
          history={this.props.history}
        />
        <RAndD lang={lang} />
      </div>
    );
  }
}

function mapStateToProps({ navigations }) {
  return {
    navigations,
  };
}

const loadData = (store, lang) => {
  return Promise.all([
    store.dispatch(actions.getListOfCustomer()),
    store.dispatch(actions.getOutsourcingServices(lang)),
    store.dispatch(actions.getAllServices(lang)),
    store.dispatch(actions.getAboutUs(lang)),
    store.dispatch(actions.getRD(lang)),
  ]);
};

export default {
  loadData,
  component: connect(mapStateToProps, actions)(Home),
};

// export const topLevelAction = () => dispatch => {
//   return Promise.all([dispatch(action1()), dispatch(action2()), dispatch(action3())])
// }
