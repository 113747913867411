import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

class LargeScreenNavBar extends Component {
  displayName = LargeScreenNavBar.name;

  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div id="largeScreenNavBar" className="largeScreenNavWrapper">
        {this.props.menus.map((item, index) => {
          if (item.dropdown === true) {
            return (
              <div key={index} className={'largeScreenNavItem ' + item.class}>
                <div className="navMoreWrapper">
                  <div className="sl-navMoreWrapper">
                    <NavLink className="navlink--parent" to={item.path}>
                      <span style={{ display: 'inline' }}>
                        {item.title}&nbsp;
                      </span>
                    </NavLink>
                    <ul className="cat-level-0">
                      <li className="cat-level-0-item">
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                        <div className="triangle-ver"></div>
                        <ul className="cat-level-1">
                          {item.children.map((subitem, index) => {
                            if (subitem.dropdown === true) {
                              return (
                                <div key={index}>
                                  <li className="cat-level-1-item">
                                    <NavLink to={subitem.path}>
                                      <span>
                                        {subitem.title}&nbsp;
                                        <i
                                          className="fa fa-angle-right icon-forward"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </NavLink>
                                    <div className="triangle-hor"></div>
                                    <ul className="cat-level-2">
                                      {subitem.children.map(
                                        (subitem, index) => {
                                          return (
                                            <div key={index}>
                                              <li className="cat-level-2-item">
                                                <NavLink
                                                  key={index}
                                                  to={subitem.path}
                                                >
                                                  <span>{subitem.title}</span>
                                                </NavLink>
                                              </li>
                                            </div>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </li>
                                </div>
                              );
                            } else {
                              return (
                                <div key={index}>
                                  <li className="cat-level-1-item">
                                    <NavLink to={subitem.path}>
                                      <span>{subitem.title}</span>
                                    </NavLink>
                                  </li>
                                </div>
                              );
                            }
                          })}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={index} className={'largeScreenNavItem ' + item.class}>
                <NavLink
                  className="paddingNavLink"
                  style={{ color: 'white' }}
                  to={item.path || 'yeah'}
                >
                  <span> {item.title} </span>
                </NavLink>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

function mapStateToProps({ navigations, lang }) {
  return {
    navigations,
    lang,
  };
}

export default connect(mapStateToProps, actions)(LargeScreenNavBar);
