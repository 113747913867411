import React from "react";
import { changeAlias } from "../../utils/ConvertVie";

var skeleton = {
  title: {
    "vi-VN": "BÀI ĐĂNG GẦN ĐÂY",
    "en-US": "RECENT POSTS",
    "ja-JP": "最近の投稿",
  },
};

function renderRecentPosts(posts) {
  return posts.map((item, index) => {
    var titleChunks = changeAlias(item.title).split(" ").join("-");
    // var newHref =
    //   window.location.host + "/posts/" + titleChunks + "_akys_" + item.post_id;
    return (
      <div
        key={index}
        onClick={() => {
          this.props.history.push(
            "/posts/" + titleChunks + "_akys_" + item.post_id
          );
        }}
        className="post post-widget mb-30"
      >
        <a className="post-img" href="/">
          <img src={item.bannerImage} alt="" />
        </a>
        <div className="post-body">
          <div className="post-category">
            <a href="/">{item.title}</a>
          </div>
          <h6 className="post-title">
            <a href="/">
              Ne bonorum praesent cum, labitur persequeris definitionem quo cu?
            </a>
          </h6>
        </div>
      </div>
    );
  });
}

function renderNotFound() {
  return (
    <div className="mt-15 mb-15">
      <p className="p--small">not found</p>
    </div>
  );
}

export default function RecentPostWidget(props) {
  var lang = props.lang || "en-US";

  return (
    <div className="widget-recent-posts mt-30">
      <h4 className="widget-title">{skeleton.title[lang]}</h4>
      {props.posts.length === 0
        ? renderNotFound()
        : renderRecentPosts(props.posts)}
    </div>
  );
}
