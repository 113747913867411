import React, { Component } from 'react';
import socketIoClient from 'socket.io-client';

var defaultMsgData = [
  {
    id: 1,
    from: 'Admin',

    datetime: '2015-05-16T05:50:06',
    content:
      'Hello, welcome to our customer service. Please give us some infomration',
  },
];

export class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgList: defaultMsgData,
      currentUser: 'John',
      supporter: 'Admin',
      currentMessage: '',
      isTyping: false,
      room: '',
      isAdmin: false,
    };
    this.socketChat = ' ';
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.handleSendButton();
      setTimeout(() => {
        this.setState({
          currentMessage: this.state.currentMessage.replace('\n', ''),
        });
      }, 0);
    }
  }

  scrollMsgListToBottom() {
    var objDiv = document.getElementById('chatMsgList');
    objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
  }

  componentDidMount() {
    this.scrollMsgListToBottom();
  }

  initializeSocketChat() {
    var host =
      process.env.NODE_ENV === 'production'
        ? 'https://itsctech-vn.com'
        : 'http://localhost:3110';
    if (this.socketChat === ' ') {
      this.socketChat = socketIoClient(host, { path: '/chat-now' });
      this.socketChat.on('SYSTEM_MSG', (data) =>
        this.receiveMessage(data, 'SYSTEM ')
      );
      this.socketChat.on('TALK', (data) => this.receiveMessage(data, 'ADMIN '));
    }
  }

  receiveMessage(data, sender) {
    defaultMsgData.push({
      id: defaultMsgData.length + 1,
      from: this.state.supporter,
      datetime: '2015-05-16T05:50:07',
      content: data,
    });

    this.setState({ msgList: defaultMsgData, currentMessage: '' }, () => {
      this.scrollMsgListToBottom();
    });
  }

  handleSendButton() {
    if (this.state.currentMessage === '') return;

    this.initializeSocketChat();

    this.socketChat.emit('TALK', this.state.currentMessage);

    defaultMsgData.push({
      id: defaultMsgData.length + 1,
      from: this.state.currentUser,
      datetime: '2015-05-16T05:50:07',
      content: this.state.currentMessage,
    });

    this.setState({ msgList: defaultMsgData, currentMessage: '' }, () => {
      this.scrollMsgListToBottom();
    });
  }

  handleTextAreaChange(e) {
    clearTimeout(this.timeoutId);
    // this.setState({ isTyping: true });
    this.setState({ currentMessage: e.target.value });
    // this.timeoutId = setTimeout(() => {
    //   this.setState({ isTyping: false });
    // }, 1000);
  }

  handleClickOnChatEffect() {
    document.getElementById('chatboxWrapper').style.display = 'flex';
    document.getElementById('chatEffect').style.display = 'none';
    this.scrollMsgListToBottom();
  }

  handleClickOnX() {
    document.getElementById('chatboxWrapper').style.display = 'none';
    document.getElementById('chatEffect').style.display = 'flex';
  }

  render() {
    return (
      <div id="chatContainer" className="chatContainer">
        <div
          id="chatEffect"
          className="chatEffect"
          onClick={() => {
            this.handleClickOnChatEffect();
          }}
        >
          <div className="supporterIcon">
            <img
              alt="chat supporter icon"
              src="/my-content/resources/mail-icon.png"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div id="chatboxWrapper" className="chatboxWrapper">
          <div className="chatboxReceiver">
            <div className="chatboxReceiverLeft">
              <h4>{this.state.supporter}</h4>
            </div>
            <div
              className="chatboxReceiverRight"
              onClick={() => {
                this.handleClickOnX();
              }}
            >
              <h4 style={{ cursor: 'default' }}>x</h4>
            </div>
          </div>
          <div className="chatboxSender">
            <div className="listOfMsgContainer">
              <div id="chatMsgList" className="listOfMessages">
                {this.state.msgList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="msgItem"
                      style={{
                        background:
                          item.from === this.state.currentUser
                            ? 'gainsboro'
                            : 'dodgerblue',
                        color:
                          item.from === this.state.currentUser
                            ? 'black'
                            : 'white',
                      }}
                    >
                      <h6>{item.content}</h6>
                    </div>
                  );
                })}
              </div>
              {this.state.isTyping && (
                <div className="typingEffect">
                  <img
                    src="/my-content/resources/typingdot.gif"
                    width="30px"
                    height="20px"
                    alt="dots"
                  />
                  <h6 style={{ color: 'black' }}>
                    &nbsp;{this.state.supporter} is typing
                  </h6>
                </div>
              )}
            </div>
            <div
              className="messageInput"
              onKeyDown={(evt) => {
                this.handleKeyDown(evt);
              }}
            >
              <div className="msgTextInput">
                <textarea
                  value={this.state.currentMessage}
                  onChange={(evt) => {
                    this.handleTextAreaChange(evt);
                  }}
                  name="Text1"
                  rows="5"
                ></textarea>
              </div>
              <div className="msgButtonInput">
                <img
                  onClick={() => {
                    this.handleSendButton();
                  }}
                  src="/my-content/resources/send.svg"
                  height="30"
                  width="30"
                  alt="sendIcon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
