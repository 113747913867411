import React from "react";

var skeleton = {
  title: {
    "vi-VN": "DANH MỤC",
    "en-US": "CATEGORIES",
    "ja-JP": "カテゴリー",
  },
};

export default function PostCategoryWidget(props) {
  var lang = props.lang || "en-US";
  return (
    <div className="widget-categories mt-30">
      <h4>{skeleton.title[lang]}</h4>
      <ul className="">
        {props.categories == null
          ? null
          : props.categories.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => props.getPostByCategory(item.id, props.lang)}
                >
                  <a href="/">
                    {item.display_name}
                    <span>451</span>
                  </a>
                </li>
              );
            })}
      </ul>
    </div>
  );
}
