import React, { Component } from 'react';
import { Row, Col, Grid, PageHeader } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Slider from 'react-slick';
import HalfVPCarousel from '../../components/HalfVPCarousel';
import { Helmet } from 'react-helmet';
import skeleton from './skeleton';
import Skeleton from 'react-loading-skeleton';
import ProductItemPortrait from './ProductItemPortrait';

var settings = {
  dots: true,
  slidesToShow: 4,
  infinite: true,
  slidesToScroll: 1,
  autoplay: true,
  swipeToSlide: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  centerMode: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        pauseOnHover: true,
        autoPlay: true,
      },
    },
    {
      breakpoint: 450,
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        autoPlay: true,
      },
    },
  ],
};

var sliding = [
  {
    title: 'ITSC TECHNOLOGOGY SOLUTIONS',
    imgSrc: '/my-content/resources/products/products-top.png',
    description: '',
  },
  {
    title: 'ITSC TECHNOLOGOGY SOLUTIONS',
    imgSrc: '/my-content/resources/services/services-top.png',
    description: '',
  },
];

const responsive = {
  0: { items: 2 },
  768: { items: 3 },
  1024: { items: 4 },
};

const NoProduct = (props) => (
  <div className="ml-50">
    <p>
      {props.lang === 'vi-VN'
        ? 'CHƯA CÓ SẢN PHẨM'
        : props.lang === 'en-US'
        ? 'NO ADDED PRODUCT'
        : props.lang === 'ja-JP'
        ? '追加製品なし'
        : 'NO ADDED PRODUCT'}
    </p>
  </div>
);

class ProductsL2 extends Component {
  constructor(props) {
    super(props);
    this.catL2Id = this.props.match.params.l2Id || 0;
    this.onSlideChange = this.onSlideChange.bind(this);
    this.onSlideChanged = this.onSlideChanged.bind(this);
  }

  componentDidMount() {
    this.props.getProductsL2(this.catL2Id, this.props.lang);
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang !== prevProps.lang) {
      this.props.getProductsL2(this.catL2Id, this.props.lang);
    } else if (this.props.match.params.l2Id !== prevProps.match.params.l2Id) {
      this.props.getProductsL2(this.props.match.params.l2Id, this.props.lang);
    }
  }

  onSlideChange(e) {}

  onSlideChanged(e) {}

  renderProducts(products) {
    if (!Array.isArray(products)) return null;
    if (products.length <= 0) return <NoProduct lang={this.props.lang} />;
    var listItems = products.map((i, index) => (
      <Col key={index} xs={6} sm={4} md={3} className="col--for-carousel">
        <ProductItemPortrait
          lang={this.props.lang}
          key={index}
          title={i.name}
          description={i.description}
          imgSrc={i.bannerImage}
          path={'/' + this.props.lang.slice(0, 2) + i.path}
          views={i.views}
        />
      </Col>
    ));

    if (products.length <= 4) {
      return products.map((i, index) => (
        <Col key={index} xs={6} sm={4} md={3}>
          <ProductItemPortrait
            lang={this.props.lang}
            key={index}
            title={i.name}
            description={i.description}
            imgSrc={i.bannerImage}
            path={'/' + this.props.lang.slice(0, 2) + i.path}
            views={i.views}
          />
        </Col>
      ));
    } else {
      return products.length > 0 && <Slider {...settings}>{listItems}</Slider>;
    }
  }

  renderCats(arr) {
    var { lang } = this.props;
    if (!Array.isArray(arr)) return null;
    if (arr.length <= 0) return null;
    else {
      return arr.map((item, index) => (
        <div key={index} className="wrapper--product-cat-all">
          <Row className="wrapper--product-cat-l2-top">
            <Col xs={12}>
              <div className="wrapper--image-circle">
                <img
                  alt="I let it here and fill it later"
                  src={item.thumbnail}
                />
              </div>
              <div className="head--image-circle">
                <div className="wrapper--product-image-circle">
                  <h4>{item.display_name}</h4>&nbsp;
                </div>
                <div className="button--see-all mt-15 mb-15">
                  <a href={'/' + this.props.lang.slice(0, 2) + item.path}>
                    <span>{skeleton.seeAll[lang]} ></span>
                  </a>
                </div>
              </div>
              <p>{item.description}</p>
            </Col>
          </Row>
          <Row className="wrapper--product-cat-l2-bottom">
            {this.renderProducts(item.products)}
          </Row>
        </div>
      ));
    }
  }

  render() {
    var { parent, children } = this.props;
    return (
      <div className="wrapper--product-l2">
        {parent === null ? null : parent.length <= 0 ? null : (
          <Helmet>
            <title>
              {skeleton.pageL2Title[this.props.lang]}
              {parent.display_name || ''}
            </title>
            <meta
              property="og:title"
              content={
                skeleton.pageL2Title[this.props.lang] +
                (parent.display_name || '')
              }
            />

            <meta
              property="og:description"
              content={parent.description || ''}
            />
          </Helmet>
        )}
        <Grid fluid>
          <Row>
            <div className="half-vh__except-nav fit-carousel">
              <HalfVPCarousel slidingData={sliding} />
            </div>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Col xs={12}>
              <PageHeader className="ml-15">
                {parent.display_name || <Skeleton count={5} />}
              </PageHeader>
            </Col>
          </Row>
          {children.length === 0 ? null : this.renderCats(children)}
          {/* <Row>
            <Col xs={12}>
              <PageHeader className="ml-15">OTHER CATEGORIES</PageHeader>
            </Col>
          </Row> */}
          {/* <Row>
            <Col xs={12} sm={6}>
              <ProductCategoryItem
                title="Automated Guided Vehicle"
                lang={lang}
                imgSrc="/my-content/resources/products/vending01.png"
              />
            </Col>

            <Col xs={12} sm={6}>
              <ProductCategoryItem
                title="Automated Guided Vehicle"
                lang={lang}
                imgSrc="/my-content/resources/products/vending02.png"
              />
            </Col>
          </Row> */}
        </Grid>
      </div>
    );
  }
}

function mapStateToProps({ productCat: { parent, children } }) {
  return { parent, children };
}

function loadData(store, lang, params) {
  return store.dispatch(actions.getProductsL2(params.l2Id, lang));
}

export default {
  component: connect(mapStateToProps, actions)(ProductsL2),
  loadData,
};
