export const TEST = "just_test";

export const GET_POSTS = "get_posts";

export const GET_ONE_POST = "get_one_post";

export const GET_ALL_CATEGORIES = "get_all_categories";

export const GET_POST_BY_CATEGORY = "get_post_by_category";

export const GET_CUSTOMERS = "get_customers";

export const GET_ALL_ABOUT_OUTSOURCING = "get_outsourcing"

export const GET_PRODUCT_BY_ID = "get_product_by_id";

export const GET_PRODUCTS_BY_CAT_ID = "get_products_by_cat_id";

export const GET_ALL_ABOUT_ABOUTUS = "get_all_aboutus";

export const GET_ALL_ABOUT_RD = "get_all_rd";

export const GET_ALL_ABOUT_EDU = "get_all_edu";

export const UPDATE_LANG = "update_lang";
export const FETCH_USER = "fetch_user";

export const GET_PRODUCT_CATEGORIES_L1 = "get_product_categories_l1";
export const GET_PRODUCT_CATEGORIES_L2 = "get_product_categories_l2";