import React, { Component } from 'react';
import { Col, Row, Grid } from 'react-bootstrap';
import landing_en from './en';
import landing_vi from './vi';
import landing_jp from './jp';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Particles from 'react-particles-js';
import particlesParams from '../../animation/particles_network.json';
import Slider from 'react-slick';

const skeleton = {
  'vi-VN': landing_vi,
  'en-US': landing_en,
  'ja-JP': landing_jp,
};

var settings = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 500,
      settings: {
        speed: 2000,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

class LandingPage extends Component {
  displayName = LandingPage.name;

  constructor(props) {
    super(props);
    this.state = {
      landingPageData: skeleton[this.props.lang],
    };
  }

  changeLang(lang_code) {
    switch (lang_code) {
      case 'vi-VN':
        this.setState({ landingPageData: landing_vi });
        break;
      case 'en-US':
        this.setState({ landingPageData: landing_en });
        break;
      case 'ja-JP':
        this.setState({ landingPageData: landing_jp });
        break;
      default:
        this.setState({ landingPageData: landing_vi });
        break;
    }
  }

  scrollToDiv(pos) {
    var scrollPosition = '';
    console.log(pos);
    switch (pos.position) {
      case 'landing':
        scrollPosition = 'landingPage';
        break;
      case 'aboutus':
        scrollPosition = 'aboutus';
        break;
      case 'outsourcing':
        scrollPosition = 'outsourcingPage';
        break;
      case 'rd':
        scrollPosition = 'rdPage';
        break;
      case 'education':
        scrollPosition = 'educationPage';
        break;
      case 'footer':
        scrollPosition = 'footer';
        break;
      case 'redirect':
        this.props.history.push(pos.location);
        break;
      case 'telephone':
        this.handleContactUs();
        break;
      default:
        break;
    }
    const element = document.getElementById(scrollPosition);
    if (element === null) return;
    const offset = 75;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.changeLang(this.props.lang);
    }
  }

  clickHook = (item) => {
    this.scrollToDiv(item);
  };

  handleContactUs = () => {
    document.getElementById('telephoneCall').click();
    //console.log('HAHA');
  };

  render() {
    return this.state.landingPageData === null ? null : (
      <Grid fluid id="landingPage" className="landingPage">
        <Row>
          <div id="particlescomponent">
            <Particles params={particlesParams} />
          </div>
          <div className="backgroundLayerLeft" />

          <Grid className="full-height wrapper--landing-inside">
            <Row className="wrapper--top">
              <Col xs={12} sm={6} md={6} lg={7} className="landingPageLeft">
                <div className="landingPageLeftContent">
                  <div className="landingPageLeftWrapper">
                    <div className="top fade-in-top-down">
                      <img
                        src={this.state.landingPageData.leftPanel[5]}
                        alt="asd"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={12}
                sm={6}
                md={6}
                lg={5}
                className="landingPageRight fade-in-lr"
              >
                <div className="landingPageRightContent">
                  <div className="landingPageRightContentContactUs">
                    <div className="col23">
                      <a id="telephoneCall" href="tel:023 67 300 779"></a>
                      <button
                        onClick={() => {
                          this.handleContactUs();
                        }}
                        className="contactUs"
                      >
                        <span>
                          {this.state.landingPageData.callToAction.buttonName}
                        </span>
                      </button>

                      <div className="test">
                        <input
                          type="text"
                          placeholder={
                            this.state.landingPageData.callToAction
                              .textboxPlaceHolder
                          }
                        />
                        <button>
                          <img
                            alt="search icon"
                            src="/my-content/resources/searchIcon.png"
                            width="30px"
                            height="30px"
                          ></img>
                        </button>
                      </div>
                      <div className="fourPoints">
                        {this.state.landingPageData.hookingQuestions.map(
                          (item, index) => {
                            return (
                              <p
                                onClick={() => {
                                  this.clickHook(item);
                                }}
                                className="p--small"
                                key={index}
                              >
                                {item.description}&nbsp;
                                <span>
                                  <img
                                    alt="blue forward icon"
                                    src="/my-content/resources/forwardIcon.png"
                                    width="15px"
                                    height="15px"
                                  />
                                </span>
                              </p>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="wrapper--bottom">
              <Col xs={12}>
                <div className="wrapper--slider fade-in">
                  {/* <i className="fa fa-chevron-circle-left icon--arrow-back"></i>
                  <i className="fa fa-chevron-circle-right icon--arrow-next"></i> */}
                  <Slider {...settings}>
                    <div>
                      <img
                        src="/my-content/resources/landingpage01-sm.png"
                        alt="landingpage01.png"
                      />
                    </div>
                    <div>
                      <img
                        src="/my-content/resources/landingpage02-sm.png"
                        alt="landingpage02.png"
                      />
                    </div>
                    <div>
                      <img
                        src="/my-content/resources/landingpage03-sm.png"
                        alt="landingpage03.png"
                      />
                    </div>
                    <div>
                      <img
                        src="/my-content/resources/engine-sm.png"
                        alt="landingpage04-sm.png"
                      />
                    </div>
                    <div>
                      <img
                        src="/my-content/resources/pano1-sm.png"
                        alt="landingpage05-sm.png"
                      />
                    </div>
                    <div>
                      <img
                        src="/my-content/resources/pano6-sm.png"
                        alt="landingpage06-sm.png"
                      />
                    </div>
                  </Slider>
                </div>
              </Col>
            </Row>
          </Grid>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, actions)(LandingPage);
