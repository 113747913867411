import React, { Component } from 'react';
import { Row, Col, Grid, PageHeader } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import ProductItemLandscape from './ProductItemLandscape';

const skeleton = {
  prodList: {
    'vi-VN': 'DANH SÁCH SẢN PHẨM ',
    'en-US': 'LIST OF PRODUCTS OF ',
    'ja-JP': '製品一覧 - ',
  },
  pageL3Title: {
    'en-US': 'Series - ',
    'vi-VN': 'Dòng sản phẩm - ',
    'ja-JP': 'シリーズ - ',
  },
};

class ProductsL3 extends Component {
  constructor(props) {
    super(props);
    this.catL3Id = this.props.match.params.l3Id || 0;
  }

  componentDidMount() {
    this.props.getProductsByCatId(this.catL3Id, this.props.lang);
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang !== prevProps.lang) {
      this.props.getProductsByCatId(this.catL3Id, this.props.lang);
    } else if (this.props.match.params.l3Id !== prevProps.match.params.l3Id) {
      this.props.getProductsByCatId(
        this.props.match.params.l3Id,
        this.props.lang
      );
    }
  }

  renderProducts(arr) {
    if (!Array.isArray(arr)) return null;
    return (
      <>
        {arr.map((i, index) => (
          <ProductItemLandscape
            lang={this.props.lang}
            key={index}
            title={i.name}
            description={i.description}
            imgSrc={i.bannerImage}
            path={'/' + this.props.lang.slice(0, 2) + i.path}
            views={i.views}
          />
          // <NavLink key={index} to={"/" + this.props.lang.slice(0, 2) + i.path}>
          //   <Col xs={12} sm={6} md={4} lg={4}>
          //     <ProdItem
          //       key={index}
          //       needShadow={true}
          //       title={i.name}
          //       description={i.description}
          //       imgSrc={i.bannerImage}
          //     />
          //   </Col>
          // </NavLink>
        ))}
      </>
    );
  }

  backImage(url) {
    if (url === null) return {};
    return {
      backgroundImage: `url("${url}")`,
    };
  }

  render() {
    var lang = this.props.lang || 'en-US';
    var { parent, children } = this.props;
    return (
      <div className="wrapper--product-l3">
        <Helmet>
          <title>
            {skeleton.pageL3Title[this.props.lang]}
            {parent.display_name || ''}
          </title>
          <meta
            property="og:title"
            content={
              skeleton.pageL3Title[this.props.lang] +
              (parent.display_name || '')
            }
          />
          <meta property="og:description" content={parent.description || ''} />
        </Helmet>

        {Object.keys(parent).length === 0 ? (
          <div className="section-padding-80">
            <Skeleton count={5} />
          </div>
        ) : (
          <div>
            <Grid fluid>
              <Row>
                <div
                  style={this.backImage(parent.image || null)}
                  className="fullscreen__except-nav"
                >
                  <div className="overlay--center">
                    <div className="half-transparent">
                      <h1>{parent.display_name || ''}</h1>
                    </div>
                  </div>
                </div>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <PageHeader className="ml-15">
                  {skeleton.prodList[lang] +
                    (!parent.display_name
                      ? null
                      : parent.display_name.toUpperCase())}
                </PageHeader>
              </Row>
              <Row>
                <Col xs={12}>
                  <p>{parent.description || ''}</p>
                </Col>
              </Row>
              {children.length > 0 ? this.renderProducts(children) : null}
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps({ products: { parent, children } }) {
  return { parent, children };
}

function loadData(store, lang, params) {
  return store.dispatch(actions.getProductsByCatId(params.l3Id, lang));
}

export default {
  component: connect(mapStateToProps, actions)(ProductsL3),
  loadData,
};
