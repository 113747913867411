import { combineReducers } from 'redux';
import testReducer from './testReducer';
import postReducer from './postReducer';
import onePostReducer from './onePostReducer';
import categoryReducer from './categoryReducer';
import customerReducer from './customerReducer';
import outsourcingReducer from './outsourcingReducer';
import aproductReducer from './aproductReducer';
import productsReducer from './productsReducer';
import aboutusReducer from './aboutusReducer';
import rdReducer from './rdReducer';
import eduReducer from './eduReducer';
import serviceReducer from './serviceReducer';
import adminReducer from './adminReducer';
import langReducer from './langReducer';
import navReducer from './navReducer';
import productCat from './productCatReducer';
import authReducer from './authReducer';
import modalReducer from './modalReducer';

export default combineReducers({
  test: testReducer,
  posts: postReducer,
  onePost: onePostReducer,
  categories: categoryReducer,
  customers: customerReducer,
  outsourcingServices: outsourcingReducer,
  aproduct: aproductReducer,
  products: productsReducer,
  aboutUs: aboutusReducer,
  admin: adminReducer,
  rd: rdReducer,
  edu: eduReducer,
  lang: langReducer,
  productCat: productCat,
  navigations: navReducer,
  services: serviceReducer,
  auth: authReducer,
  modal: modalReducer,
});
