import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Row, Col, Grid, PageHeader } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import ProdItem from '../products/ProdItem';
import HalfVPCarousel from '../../components/HalfVPCarousel';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';

var sliding = [
  {
    title: 'ITSC TECHNOLOGOGY SOLUTIONS',
    imgSrc: '/my-content/resources/services/27122018.png',
    description: '',
  },
  {
    title: 'ITSC TECHNOLOGOGY SOLUTIONS',
    imgSrc: '/my-content/resources/services/services-top.png',
    description: '',
  },
];

class ServiceSingle extends Component {
  constructor(props) {
    super(props);
    this.serviceId = this.props.match.params.serviceNameId;
  }

  componentDidMount() {
    this.props.getServices(this.serviceId, this.props.lang);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.serviceNameId !==
      this.props.match.params.serviceNameId
    ) {
      this.props.getServices(
        this.props.match.params.serviceNameId,
        this.props.lang
      );
    }
  }

  backImage(url) {
    if (url === null) return {};
    return {
      backgroundImage: `url("${url}")`,
    };
  }

  renderProducts(arr) {
    if (!Array.isArray(arr)) return null;
    return (
      <>
        {arr.map((i, index) => (
          <NavLink to={i.path}>
            <Col xs={12} sm={6} md={4} lg={4}>
              <ProdItem
                key={index}
                needShadow={true}
                title={i.name}
                description={i.description}
                imgSrc={i.img}
              />
            </Col>
          </NavLink>
        ))}
      </>
    );
  }

  render() {
    var { overview } = this.props;
    return (
      <div>
        <Helmet>
          <title>{'ITSC - ' + (overview.display_name || '')}</title>
          <meta
            property="og:title"
            content={'ITSC - ' + (overview.display_name || '')}
          />
          <meta
            property="og:description"
            content={'ITSC - ' + (overview.display_name || '')}
          />
        </Helmet>
        <Grid fluid className="mt-70">
          <Row>
            <div className="half-vh__except-nav fit-carousel">
              <HalfVPCarousel slidingData={sliding} />
            </div>
          </Row>
        </Grid>
        <Grid className="">
          <Row className="wrapper--products">
            <Col xs={12} sm={12} md={12} lg={12}>
              <PageHeader>{overview.display_name || <Skeleton />}</PageHeader>
              <div className="service-article">
                {!overview.post ? (
                  <Skeleton count={5} />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: overview.post,
                    }}
                  ></div>
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps({
  services: {
    oneService: { overview },
  },
}) {
  return { overview };
}

function loadData(store, lang, params) {
  return store.dispatch(actions.getServices(params.serviceNameId, lang));
}

export default {
  component: connect(mapStateToProps, actions)(ServiceSingle),
  loadData,
};
