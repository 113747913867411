import React, { Component } from 'react';
import { connect } from 'react-redux';
import createDOMPurify from 'dompurify';
//import { JSDOM } from 'jsdom';
import defaultPostData from './postData/defaultPostData';
import * as actions from '../../actions';

//const window = new JSDOM('').window;
//const DOMPurify = createDOMPurify(window);

class Posts extends Component {
  displayName = Posts.name;

  constructor(props) {
    super(props);
    this.state = {
      idOfNews: '123',
      postData: defaultPostData,
    };
    this.postHint = this.props.match.params.path;
  }

  fetchPost(postPath) {
    fetch(postPath)
      .then((response) => {
        response.json();
      })
      .then((responseJson) => {
        this.processPostData(responseJson);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  getPostFromHint(hintString) {
    var postIdString = hintString.split('_akys_')[1];
    if (!postIdString) return;
    try {
      var postId = parseInt(postIdString);
      this.props.getPostById(postId, this.props.lang);
    } catch (error) {
      console.log('error' + error);
    }
  }

  componentDidMount() {
    this.getPostFromHint(this.postHint);
  }

  render() {
    return this.props.onePost == null ? null : (
      <div className="pageWrapper postWrapper section-padding-80">
        <div className="postItem">
          <div className="postTitle">
            <h1>{this.props.onePost.title}</h1>
          </div>
          <div className="postDateTime">
            <h5>{this.props.onePost.created_datetime || ''}</h5>
          </div>
          <div className="postAuthor">
            <h4>author: {this.props.onePost.author}</h4>
          </div>
          <div
            className="postContent"
            dangerouslySetInnerHTML={{
              __html: this.props.onePost.content,
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ onePost }) {
  return {
    onePost,
  };
}

function loadData(store, lang, params) {
  return store.dispatch(
    actions.getPostById(parseInt(params.path.split('_akys_')), lang)
  );
}

export default {
  component: connect(mapStateToProps, actions)(Posts),
  loadData,
};
