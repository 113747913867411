import React, { Component } from 'react';
import { renderRoutes } from 'react-router-config';
import * as actions from './actions';
import { connect } from 'react-redux';
import NavigationBar from './navigation-bar/NavigationBar';
import Footer from './pages/footer/Footer';
import SearchOverlay from './search-overlay/SearchOverlay';
import { Chat } from './components/chat/Chat';
import AkysModal from './components/AkysModal';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testVar: 1,
      language: 'en',
      wrapperClass: '',
      overlayEnabled: false,
      show: false,
      modalContent: 'request-ok',
    };

    this.selector = React.createRef();
  }

  searchAction() {
    this.setState({
      wrapperClass: 'wrapper__overlay',
      overlayEnabled: true,
    });
  }

  searchEnd() {
    this.setState({
      wrapperClass: '',
      overlayEnabled: false,
    });
  }

  handleCloseModal() {
    this.setState({ show: false });
  }

  showModal(data) {
    this.setState(
      {
        modalContent: data,
      },
      () => {
        this.setState({ show: true });
      }
    );
  }

  render() {
    const { route, content, visible, topic } = this.props;

    return (
      <div className={this.state.wrapperClass}>
        <NavigationBar
          lang={route.lang}
          onSearchBtnClick={() => this.searchAction()}
        />
        <SearchOverlay
          onCloseSearchClick={() => this.searchEnd()}
          enabled={this.state.overlayEnabled}
        />
        {renderRoutes(route.routes, { lang: route.lang })}
        <Footer
          showModal={(data) => {
            this.props.showModalContent('quick-info', '');
          }}
          id="footer"
          lang={route.lang}
        />
        <Chat lang={route.lang} />
        <AkysModal
          lang={route.lang}
          topic={topic}
          content={content}
          show={visible}
          onClose={() => {
            this.props.hideModalContent();
          }}
        />
      </div>
    );
  }
}

function mapStateToProps({ test, auth, modal: { content, visible, topic } }) {
  return {
    test,
    auth,
    visible,
    content,
    topic,
  };
}

export default {
  component: connect(mapStateToProps, actions)(App),
  // loadData: ({ dispatch }) => dispatch(fetchUser()),
};
