import React from 'react';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Form,
  Row,
  Grid,
  Col,
  HelpBlock,
} from 'react-bootstrap';
import axios from 'axios';
import errReport from './errReport';

const skeleton = {
  title: {
    'vi-VN': 'Bạn phải đăng nhập để tiếp tục',
    'en-US': 'You must sign in to continue',
    'ja-JP': 'You must sign in to continue',
  },
  title2: {
    'vi-VN': 'Bạn chưa có tài khoản?',
    'en-US': 'Not have an account?',
    'ja-JP': 'Not have an account?',
  },
  forgot: {
    'vi-VN': 'Quên mật khẩu?',
    'en-US': 'Forgot passwords?',
    'ja-JP': 'Forgot passwords?',
  },
  create: {
    'vi-VN': 'Tạo tài khoản mới?',
    'en-US': 'Create an account?',
    'ja-JP': 'Create an account?',
  },
  register: {
    'vi-VN': 'Đăng ký thành viên',
    'en-US': 'Register Now',
    'ja-JP': 'Register Now',
  },
  toDownload: {
    'vi-VN': 'Để tải catalog và nhận thông tin mới nhất từ chúng tôi',
    'en-US': 'To download catalogues and receive daily news from us',
    'ja-JP': 'To download catalogues and receive daily news from us',
  },
};

function QuickAuthorization(props) {
  const [nameState, setNameState] = React.useState({ status: null, help: '' });
  const [passState, setPassState] = React.useState({ status: null, help: '' });
  const [submitting, setSubmitting] = React.useState(false);
  var { lang } = props;

  const handleLogin = (e) => {
    e.preventDefault();
    setSubmitting(true);
    var username = e.target.username.value;
    var passwords = e.target.passwords.value;
    if (!username) {
      setNameState({ status: 'error', help: errReport.missing[lang] });
    }
    if (!passwords) {
      setPassState({ status: 'error', help: errReport.missing[lang] });
    }
    if (username && passwords.toString().length > 8) {
      axios
        .post('/api/auth/local', { username: username, password: passwords })
        .then((response) => {
          console.log(response);
          if (!response.data.status) {
            setNameState({
              status: 'error',
              help: errReport.incorrect[lang],
            });
            setPassState({
              status: 'error',
              help: errReport.incorrect[lang],
            });
            setSubmitting(false);
          } else {
            window.location.reload();
          }
        });
    } else {
      setPassState({ status: 'error', help: errReport.tooShort[lang] });
      setSubmitting(false);
    }
  };

  return (
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <p>{skeleton.title[lang]}</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <Form onSubmit={(e) => handleLogin(e)}>
            <FormGroup controlId="username" validationState={nameState.status}>
              <ControlLabel>Username</ControlLabel>
              <FormControl
                type="text"
                placeholder="example: john123"
                disabled={submitting}
              />
              <HelpBlock>{nameState.help}</HelpBlock>
            </FormGroup>
            <FormGroup controlId="passwords" validationState={passState.status}>
              <ControlLabel>Passwords</ControlLabel>
              <FormControl
                type="password"
                disabled={submitting}
                placeholder="passwords must be at least 8 characters"
              />
              <HelpBlock>{passState.help}</HelpBlock>
            </FormGroup>
            <FormGroup>
              <Button
                bsStyle="primary"
                type="submit"
                block
                disabled={submitting}
              >
                Login
              </Button>
            </FormGroup>
          </Form>
          <div>
            <a href={`/${lang.slice(0, 2)}/sign-up`}>{skeleton.forgot[lang]}</a>
          </div>
          <div>
            <a href={`/${lang.slice(0, 2)}/sign-up`}>{skeleton.create[lang]}</a>
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <h5>{skeleton.title2[lang]}</h5>
          <Button
            bsStyle="warning"
            block
            href={'/' + lang.slice(0, 2) + '/sign-up'}
          >
            {skeleton.register[lang]}
          </Button>
          <br></br>
          <p className="p--small">>{skeleton.toDownload[lang]}</p>
        </Col>
      </Row>
    </Grid>
  );
}

export default QuickAuthorization;
