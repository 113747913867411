const skeleton = {
  tabs: {
    introduction: {
      'en-US': 'INTRODUCTION',
      'vi-VN': 'TỔNG QUAN',
      'ja-JP': '前書き',
    },
    technical: {
      'en-US': 'TECHNICAL INFORMATION',
      'vi-VN': 'THÔNG SỐ KỸ THUẬT',
      'ja-JP': '技術的な案内',
    },
    other: {
      'en-US': 'OTHER INFORMATION',
      'vi-VN': 'THÔNG SỐ KHÁC',
      'ja-JP': 'その他の情報',
    },
    documents: {
      'en-US': 'DOWNLOAD CATALOGUES',
      'vi-VN': 'TẢI CATALOGUE',
      'ja-JP': 'カタログをダウンロード',
    },
  },
  button: {
    order: {
      'en-US': 'ORDER NOW',
      'vi-VN': 'ĐẶT HÀNG',
      'ja-JP': '今すぐ注文',
    },
  },
  sameCat: {
    'en-US': 'PRODUCTS IN THE SAME CATEGORIES',
    'vi-VN': 'CÁC SẢN PHẨM CÙNG DANH MỤC',
    'ja-JP': '同じカテゴリーの製品',
  },
  pageL2Title: {
    'en-US': 'Categories - ',
    'vi-VN': 'Danh mục - ',
    'ja-JP': 'カテゴリー - ',
  },
  seeAll: {
    'en-US': 'See all products',
    'vi-VN': 'Xem tất cả các sản phẩm',
    'ja-JP': 'すべての製品を見る',
  },
  btnName: {
    'vi-VN': 'Xem thêm',
    'en-US': 'Read more',
    'ja-JP': '続きを読む',
  },
};

export default skeleton;
