import React, { Component } from 'react';
import { Row, Col, Grid, Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import ProductItemPortrait from './ProductItemPortrait';
import { NavLink } from 'react-router-dom';
import HalfVPCarousel from '../../components/HalfVPCarousel';
import ProductItemLoadMore from './ProductItemLoadMore';
import { Helmet } from 'react-helmet';
import skeleton from './skeleton';

var sliding = [
  {
    title: 'ITSC TECHNOLOGOGY SOLUTIONS',
    imgSrc: '/my-content/resources/products/products-top.png',
    description: '',
  },
  {
    title: 'ITSC TECHNOLOGOGY SOLUTIONS',
    imgSrc: '/my-content/resources/services/services-top.png',
    description: '',
  },
];

const NoProduct = (props) => (
  <div>
    <p>
      {props.lang === 'vi-VN'
        ? 'CHƯA CÓ SẢN PHẨM'
        : props.lang === 'en-US'
        ? 'NO ADDED PRODUCT'
        : props.lang === 'ja-JP'
        ? '追加製品なし'
        : 'NO ADDED PRODUCT'}
    </p>
  </div>
);

class ProductsL1 extends Component {
  constructor(props) {
    super(props);
    this.catL2Id = 2;
    this.onSlideChange = this.onSlideChange.bind(this);
    this.onSlideChanged = this.onSlideChanged.bind(this);
  }

  componentDidMount() {
    this.props.getProductsL2(this.catL2Id, this.props.lang);
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang !== prevProps.lang) {
      this.props.getProductsL2(this.catL2Id, this.props.lang);
    } else if (this.props.match.params.l2Id !== prevProps.match.params.l2Id) {
      this.props.getProductsL2(this.props.match.params.l2Id, this.props.lang);
    }
  }

  onSlideChange(e) {}

  onSlideChanged(e) {}

  renderProducts(products) {
    if (!Array.isArray(products)) return null;
    if (products.length <= 0) return <NoProduct lang={this.props.lang} />;
    var listItems = products.map((i, index) => (
      <Col key={index} xs={6} sm={4} md={3}>
        <ProductItemPortrait
          lang={this.props.lang}
          key={index}
          title={i.name}
          description={i.description}
          imgSrc={i.bannerImage}
          path={`/${this.props.lang.slice(0, 2)}${i.path}`}
        />
      </Col>
    ));

    return (
      <>
        {listItems}
        <Col xs={6} sm={4} md={3}>
          <ProductItemLoadMore lang={this.props.lang} />
        </Col>
      </>
    );
  }

  renderCats(arr) {
    if (!Array.isArray(arr)) return null;
    if (arr.length <= 0) return null;
    else {
      return arr.map((item, index) => (
        <Col key={index} xs={12}>
          <Panel bsStyle="primary" className="custom-panel-1">
            <Panel.Heading>
              <h4 className="title--decoration-colors">{item.display_name}</h4>
              <NavLink
                className="link--see-all"
                to={'/' + this.props.lang.slice(0, 2) + item.path}
              >
                <span>{skeleton.seeAll[this.props.lang]} ></span>
              </NavLink>
            </Panel.Heading>
            <Panel.Body>
              <div className="product-serie-list">
                {this.renderProducts(item.products)}
              </div>
            </Panel.Body>
          </Panel>
        </Col>
      ));
    }
  }

  render() {
    var { parent, children } = this.props;
    return (
      <div>
        {parent === null ? null : parent.length <= 0 ? null : (
          <Helmet>
            <title>
              {skeleton.pageL2Title[this.props.lang]}
              {parent.display_name || ''}
            </title>
            <meta
              property="og:title"
              content={
                skeleton.pageL2Title[this.props.lang] +
                (parent.display_name || '')
              }
            />
            <meta
              property="og:description"
              content={parent.description || ''}
            />
          </Helmet>
        )}
        <Grid fluid className="wrapper--product-l2">
          <Row>
            <div className="half-vh__except-nav fit-carousel">
              <HalfVPCarousel slidingData={sliding} />
            </div>
          </Row>
        </Grid>
        <Grid>
          <Row>{children.length === 0 ? null : this.renderCats(children)}</Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps({ productCat: { parent, children } }) {
  return { parent, children };
}

function loadData(store, lang, params) {
  return store.dispatch(actions.getProductsL2(2, lang));
}

export default {
  component: connect(mapStateToProps, actions)(ProductsL1),
  loadData,
};
