const errors = {
  missing: {
    'vi-VN': 'Ô nhập thiếu',
    'en-US': 'Missing inputs',
    'ja-JP': 'Missing inputs',
  },
  incorrect: {
    'vi-VN': 'Nhập sai',
    'en-US': 'Incorrect input',
    'ja-JP': 'Incorrect input',
  },
  tooShort: {
    'vi-VN': 'Mật khẩu ngắn hơn 8 ký tự',
    'en-US': 'Password is shorter than 8 characters',
    'ja-JP': 'Password is shorter than 8 characters',
  },
};

export default errors;
