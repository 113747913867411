const footer_vi = {
  company: {
    header: 'ITSC',
    mainLinks: [
      {
        link: '/vi',
        title: 'Về Chúng Tôi',
      },
      {
        link: '/vi/opportunities',
        title: 'Tuyển dụng',
      },
      {
        link: '/vi',
        title: 'Liên hệ',
      },
      {
        link: '/vi',
        title: 'Điều khoản dịch vụ',
      },
      {
        link: '/vi',
        title: 'Chính sách riêng tư',
      },
      {
        link: '/vi/news',
        title: 'Blog',
      },
    ],
  },
  subscription: {
    header: 'Đăng Ký Nhận Thông Tin',
    paragraph: 'Nhận thông tin hữu ích về sản phẩm và dịch vụ của công ty ITSC',
  },
  contact: {
    address:
      'Địa chỉ: Tầng 4, 69 Quang Trung, Quận Hải Châu, Thành Phố Đà Nẵng, Việt Nam',
    telephone:
      'Tel: (+84) 236 3539 125  I  Fax: (+84) 236 3539 125  I  Email: info@itsctech-vn.com',
  },
};

export default footer_vi;
