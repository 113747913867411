import { GET_ALL_ABOUT_ABOUTUS } from "../actions/types";
var initialState = {info: {}, history: [], structure:[]};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ABOUT_ABOUTUS:
      return action.payload;
    default:
      return state;
  }
}


// {
//   "status": "ok" or "error",
//   "data": [] or {},
//   "message": string,
//   "error": string 
//  }