import React from "react";
import { Carousel } from "react-bootstrap";

export default class HalfVPCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = {
      index: 0,
      direction: null,
    };
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }

  render() {
    var slidingData = this.props.slidingData || [];
    const { direction } = this.state;

    return (
      <Carousel direction={direction} interval={4000}>
        {slidingData.map((item, order) => {
          return (
            <Carousel.Item key={order}>
              <img src={item.imgSrc} alt={item.title} />
              <Carousel.Caption>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  }
}
