import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import HalfVPCarousel from '../../components/HalfVPCarousel';
import { Helmet } from 'react-helmet';

const skeleton = {
  pageTitle: {
    'vi-VN': 'ITSC - Cơ Hội Thực Tập, Đào Tạo, Tuyển Dụng',
    'en-US': 'ITSC - Opportunities',
    'ja-JP': 'ITSC - 機会',
  },
};

var sliding1 = [
  {
    title: '',
    imgSrc: '/my-content/resources/staff2.jpg',
    description: '',
  },
  {
    title: '',
    imgSrc: '/my-content/resources/team.JPG',
    description: '',
  },
  {
    title: '',
    imgSrc: '/my-content/resources/football.JPG',
    description: '',
  },
];

var sliding2 = [
  {
    title: '',
    imgSrc: '/my-content/resources/team.JPG',
    description: '',
  },
  {
    title: '',
    imgSrc: '/my-content/resources/staff2.jpg',
    description: '',
  },
  {
    title: '',
    imgSrc: '/my-content/resources/football.JPG',
    description: '',
  },
];

class Opportunities extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
    }
  }

  interviewAction() {
    window.location = '/forms/clientform/658108a1-16df-4eec-8d3c-cd796fbaf451';
  }

  render() {
    return (
      <Grid fluid className="page-opportunities mt-70">
        <Helmet>
          <title>{skeleton.pageTitle[this.props.lang]}</title>
          <meta
            property="og:title"
            content={skeleton.pageTitle[this.props.lang]}
          />
          <meta
            property="og:description"
            content={skeleton.pageTitle[this.props.lang]}
          />
        </Helmet>
        <Row className="row--opportunities">
          <Col smHidden md={1} lg={1}></Col>
          <Col xs={12} sm={12} md={6} lg={5} className="col__double-stack">
            <div className="col__double-stack--top fit-carousel">
              <HalfVPCarousel slidingData={sliding1} />
            </div>
            <div className="col__double-stack--bot fit-carousel">
              <HalfVPCarousel slidingData={sliding2} />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={5} className="col__single-stack">
            <img
              alt="collaborate"
              src="/my-content/resources/collaborate.jpg"
            />
            <div className="col__single-stack--overlay">
              <h1>
                ỨNG TUYỂN NGAY <br />
                CÓ VIỆC HAY
              </h1>
              <div
                onClick={() => {
                  this.interviewAction();
                }}
                className="btn__opportunities common-shadow"
              >
                <p>PHỎNG VẤN ONLINE</p>
              </div>
              <p className="p--small">THỰC HIỆN ĐĂNG KÝ PHỎNG VẤN TẠI ITSC</p>
            </div>
          </Col>
          <Col smHidden md={1} lg={1}></Col>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default {
  component: connect(mapStateToProps, actions)(Opportunities),
};
