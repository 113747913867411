import React from "react";

var skeleton = {
  placeholder: {
    "vi-VN": "Tìm kiếm",
    "en-US": "Search",
    "ja-JP": "探す",
  },
};

export default function SearchPostWidget(props) {
  var lang = props.lang || "en-US";
  return (
    <div className="widget-search mt-40">
      <form action="#" method="post">
        <input type="text" className="textbox" placeholder={skeleton.placeholder[lang]} />
        <input title="Search" value="" type="submit" className="button" />
      </form>
    </div>
  );
}
