var initialState = { visible: false, content: '', topic: '' };

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SHOW_CONTENT':
      return {
        visible: true,
        content: action.payload.content,
        topic: action.payload.topic,
      };
    case 'HIDE_CONTENT':
      return initialState;
    default:
      return state;
  }
}
