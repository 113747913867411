import React, { Component } from "react";
var skeleton = {
  loadMore: {
    "vi-VN": "Tải thêm",
    "en-US": "Load more",
    "ja-JP": "もっと読み込む",
  },
};

export default class ProductItemLoadMore extends Component {

  render() {
    var lang = this.props.lang || "ja-JP";
    return (
      <div className="wrapper--product-item wrapper--product-item__load-more">
        <div className="wrapper--image-product-item"></div>
        <div className="title--product-item">
          <h6> </h6>
        </div>
        <div className="description--product-item">
          <p></p>
        </div>
        <div className="widget--product-item">
          <div className="wrapper--product-read-more">
            
          </div>
          <div className="wrapper--product-views">
            <p>{/* 128 <i className="fa fa-eye"></i> */}</p>
          </div>
        </div>
        <div className="wrapper--products-load-more">
            <div className="button--load-more-products mt-15 mb-15">
              <a href="/">
                <span>{skeleton.loadMore[lang]}</span>
              </a>
            </div>
          </div>
      </div>
    );
  }
}
