import React, { Component } from 'react';
//import "./NavigationBar.css";
import { Col, Grid, Row } from 'react-bootstrap';
import LanguageSelect from './LanguageSelect';
import LargeScreenNavBar from './LargeScreenNavBar';
import MobileNavBar from './MobileNavBar';
import getMenus from './navigationItems';
import { withRouter } from 'react-router-dom';
import AuthCorner from './AuthCorner';
import PropTypes from 'prop-types';

class NavigationBar extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hamActive: false,
    };
    this.colorhihi = 'white';
  }

  componentDidMount() {}

  onHamburgerButtonClick() {
    document.getElementById('MobileNavBar').style.width = '250px';
  }

  handleButtonHamburger() {
    this.setState({ hamActive: !this.state.hamActive }, () => {
      if (this.state.hamActive) {
        document.getElementById('MobileNavBar').style.width = '250px';
      } else {
        document.getElementById('MobileNavBar').style.width = 0;
      }
    });
  }

  onNavItemClick() {
    this.handleButtonHamburger();
  }

  render() {
    const { lang } = this.props;
    var { hamActive } = this.state;
    return (
      <Grid
        id="navigationBar"
        fluid
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Row
          className="navigationBar"
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          <Col className="col-xs-6 col-sm-3 col-md-3 col-lg-2 navigationBarLeft">
            <div className="navigationBarLeft1">
              <a href={`/${this.props.lang.slice(0, 2)}`}>
                <img
                  id="companyLogoTopLeft"
                  src="/my-content/resources/wide-logo-1.svg"
                  alt="yeah understand"
                />
              </a>
            </div>
          </Col>

          <Col className="col-xs-6 col-sm-9 col-md-9 col-lg-10 navigationBarRight">
            <div className="navigationBarRight__wrapper">
              <LargeScreenNavBar menus={getMenus(this.props.lang)} />
              <div
                className="searchIcon"
                onClick={() => {
                  this.props.onSearchBtnClick();
                }}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </div>
              <LanguageSelect lang={lang} />
              <AuthCorner lang={lang} />
              <div
                id="hamburgerButton"
                onClick={() => {
                  this.handleButtonHamburger();
                }}
              >
                <div
                  className={
                    hamActive === true ? 'nav-icon--clicked' : 'nav-icon'
                  }
                >
                  <div></div>
                </div>
              </div>
            </div>
          </Col>

          <MobileNavBar
            lang={lang}
            onNavItemClick={() => {
              this.onNavItemClick();
            }}
            menus={getMenus(this.props.lang)}
          />
        </Row>
      </Grid>
    );
  }
}

export default withRouter(NavigationBar);
