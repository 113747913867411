const footer_jp = {
  company: {
    header: '会社',
    mainLinks: [
      {
        link: '/ja',
        title: '約',
      },
      {
        link: 'vi/opportunities',
        title: 'キャリア',
      },
      {
        link: '/ja',
        title: '連絡先',
      },
      {
        link: '/ja',
        title: '利用規約',
      },
      {
        link: '/ja',
        title: '個人情報保護方針',
      },
      {
        link: '/ja/news',
        title: 'ブログ',
      },
    ],
  },
  subscription: {
    header: 'ニュースレターを購読する',
    paragraph:
      '便利なヒントと貴重なリソースについては、メールニュースレターを購読してください',
  },
  contact: {
    address: '住所：ベトナム、ダナン市ハイチャウ区69クアンチュンストリート4階',
    telephone:
      '電話：（+84）236 3539 125  I  ファックス：（+84）236 3539 125   I  電子メール：info@itsctech-vn.com ',
  },
};

export default footer_jp;
